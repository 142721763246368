const BaseLayout = () => import('@/elements/containers/BaseLayout');
const NewMapPage = () => import('@/elements/pages/natal-map/NewMapPage');
const EditMapPage = () => import('@/elements/pages/natal-map/EditMapPage');
const ChartPage = () => import('@/elements/pages/chart');
const ChartSettingsPage = () => import('@/elements/pages/chart-settings');
const ChartHistoryPage = () => import('@/elements/pages/chart-history/ChartHistoryPage');

const ConnectionRefused = () => import('@/elements/pages/ConnectionRefusedErrorPage');
const NotFound = () => import('@/elements/pages/NotFoundPage');

const AuthLayout = () => import(/* webpackChunkName: "auth-pages" */ '@/elements/containers/AuthLayout');
const SignUp = () => import(/* webpackChunkName: "auth-pages" */ '@/elements/pages/SignUp');
const SignIn = () => import(/* webpackChunkName: "auth-pages" */ '@/elements/pages/SignIn');
const ResetPassword = () => import(/* webpackChunkName: "auth-pages" */ '@/elements/pages/ResetPassword');
const UpdatePassword = () => import(/* webpackChunkName: "auth-pages" */ '@/elements/pages/UpdatePassword');
const ConfirmEmail = () => import(/* webpackChunkName: "auth-pages" */ '@/elements/pages/ConfirmEmail');
const ProfilePage = () => import('@/elements/pages/profile/ProfilePage');
const TariffsPage = () => import('@/elements/pages/tariffs/TariffsPage');

export default [
  {
    path: '/',
    name: 'root',
    component: BaseLayout,
    children: [
      {
        path: 'natal-chart',
        name: 'natal-chart',
        component: ChartPage,
      },
      {
        path: 'chart-settings',
        name: 'chart-settings',
        component: ChartSettingsPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'chart-history',
        name: 'chart-history',
        component: ChartHistoryPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'new-map',
        name: 'new-map',
        component: NewMapPage,
      },
      {
        path: 'edit-map',
        name: 'edit-map',
        component: EditMapPage,
      },
      {
        path: 'profile',
        name: 'profile',
        component: ProfilePage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'tariffs',
        name: 'tariffs',
        component: TariffsPage,
      },
    ],
    redirect: { name: 'natal-chart' },
  },
  {
    path: '/',
    name: 'auth',
    component: AuthLayout,
    children: [
      {
        path: 'sign-up',
        name: 'signUp',
        component: SignUp,
      },
      {
        path: 'sign-in',
        name: 'signIn',
        component: SignIn,
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: ResetPassword,
      },
      {
        path: 'update-password',
        name: 'updatePassword',
        component: UpdatePassword,
      },
      {
        path: 'confirm-email',
        name: 'confirmEmail',
        component: ConfirmEmail,
      },
    ],
  },
  {
    path: '/503',
    name: 'connectionRefused',
    component: ConnectionRefused,
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
];
