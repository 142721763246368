<template>
  <div>
    <notification-container :notifications="notifications" v-slot="{ notification }">
      <closable-notification-item
        :interval="notification.interval"
        :img="notification.img"
        :action="notification.action"
        :remember="notification.remember"
        @close="removeNotification(notification)"
      >
        {{ notification.text }}
      </closable-notification-item>
    </notification-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import NotificationContainer from './NotificationContainer';
import ClosableNotificationItem from './ClosableNotificationItem';

export default {
  name: 'AppNotificationContainer',
  components: {
    NotificationContainer,
    ClosableNotificationItem,
  },
  computed: {
    ...mapState('UserModule', ['notifications']),
  },
  methods: {
    ...mapActions('UserModule', ['removeNotification']),
  },
};
</script>
