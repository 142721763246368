<template>
  <v-dialog v-model="showDialog" persistent max-width="660">
    <v-card class="root" :elevation="0">
      <cross-button class="root__close-btn" @click="onRejectClick" />
      <div class="root__title">
        <slot></slot>
      </div>
      <text-field v-if="inputTitle" v-model="inputValue" :label="inputTitle" class="root__input" />
      <div class="root__actions">
        <AstroButton
          v-if="rejectTitle"
          @click="onRejectClick"
          class="btn btn-reject"
          color="secondary"
          :title="rejectTitle"
        />
        <AstroButton
          v-if="submitTitle"
          class="btn"
          @click="onSubmitClick"
          :title="submitTitle"
          :disabled="isSubmitting"
          :loading="isSubmitting"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AstroButton from '@/elements/components/AstroButton';
import CrossButton from '@/elements/components/CrossButton';
import TextField from '@/elements/components/TextField';

export default {
  name: 'ConfirmDialog',
  components: {
    AstroButton,
    CrossButton,
    TextField,
  },
  model: {
    prop: 'show',
    event: 'hide',
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    submitTitle: {
      type: String,
      default: null,
    },
    rejectTitle: {
      type: String,
      default: null,
    },
    inputTitle: {
      type: String,
      default: null,
    },
    value: {
      type: [Function, String],
      default: null,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { inputValue: this.value };
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.hide();
      },
    },
  },
  methods: {
    hide() {
      this.$emit('hide', false);
    },
    onRejectClick() {
      this.$emit('reject');
      this.hide();
    },
    onSubmitClick() {
      this.$emit('submit', this.inputValue);
      this.hide();
    },
  },
};
</script>

<style scoped lang="sass">
$dialog-border-radius: base-unit(5)

::v-deep .v-dialog
  border-radius: $dialog-border-radius

.root
  position: relative
  border-radius: $dialog-border-radius
  padding: base-unit(20)
  display: flex
  flex-direction: column
  align-items: center
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05)
  +small-devices
    padding: base-unit(30)
  +medium-devices
    padding: base-unit(40)

  &__close-btn
    position: absolute
    right: base-unit(10)
    top: base-unit(10)
    +small-devices
      right: base-unit(20)
      top: base-unit(20)
    +medium-devices
      right: base-unit(25)
      top: base-unit(25)

  &__title
    margin: 0 auto base-unit(20)
    max-width: 90%
    text-align: center
    color: grey-color('500')
    font-weight: 500
    font-size: $base-font-size

  &__input
    width: 100%
    +medium-devices
      max-width: 80%

  &__actions
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column-reverse
    +small-devices
      flex-direction: row

  .btn
    width: 100%
    +small-devices
      width: auto
    +extra-small-devices
      padding: 0 base-unit(15)

    &-reject
      margin-top: base-unit(10)
      +small-devices
        margin-top: 0
        margin-right: base-unit(10)
    ::v-deep .loading
      +small-devices
        left: -1 * base-unit(17)
        width: 15px !important
</style>
