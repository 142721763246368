import Vue from 'vue';
import { mapGetters } from 'vuex';

import { isAllowedForUser } from '@/utils/subscriptions';

Vue.mixin({
  computed: {
    ...mapGetters('UserModule', ['selectedTariffPlan']),
  },
  methods: {
    hasAccess(requiredTariffPlan) {
      return isAllowedForUser(this.selectedTariffPlan, requiredTariffPlan);
    },
  },
});
