import { AspectType } from 'astro-chart';
import { transformApiConfigToLibConfig, transformUIConfigToApiConfig } from './orbs-config';

export const transformUISettingsToApiSettings = (settings) => {
  return {
    mapSettings: {
      zoom: {
        ...settings.zoomEnabled,
      },
      convergingAspects: settings.calculatedAspectType === AspectType.CONVERGING,
      houseSystem: settings.houseSystem,
      orbCalculationType: settings.calculatedOrbType,
      orbs: transformUIConfigToApiConfig(settings.orbsConfig, settings.aspectsConfig),
      northCityHouseSystem: settings.northernHouseSystem,
      northCityLongitude: settings.northernSystemDegree,
      presetSettings: settings.presetSettings,
    },
  };
};

export const transformApiSettingsToUISettings = (settings) => {
  const { mapSettings } = settings;
  const {
    presetSettings: { orbs, type },
  } = mapSettings;

  return {
    zoomEnabled: {
      ...mapSettings.zoom,
    },
    ...transformApiConfigToLibConfig(orbs),
    calculatedAspectType: mapSettings.convergingAspects ? AspectType.CONVERGING : null,
    calculatedOrbType: mapSettings.orbCalculationType,
    houseSystem: mapSettings.houseSystem,
    northernHouseSystem: mapSettings.northCityHouseSystem,
    northernSystemDegree: mapSettings.northCityLongitude,
    presetType: type,
  };
};
