import { OrbCalculationType } from 'astro-chart';

import actions from '@/store/modules/user/actions';
import mutations from '@/store/modules/user/mutations';
import getters from '@/store/modules/user/getters';
import { HOUSE_SYSTEM_TYPE } from '@/types';
import { initOrbsConfig } from '@/store/modules/user/utils/orbs-defaults';
import { initAspectsConfig } from '@/store/modules/user/utils/aspects-defaults';
import { DEFAULT_NORTH_CHART_DEGREE } from '@/constants';

export const getDefaultUserSettings = () => ({
  zoomEnabled: {
    desktop: true,
    mobile: false,
  },
  orbsConfig: initOrbsConfig(),
  aspectsConfig: initAspectsConfig(),
  calculatedAspectType: null,
  calculatedOrbType: OrbCalculationType.MAX,
  houseSystem: HOUSE_SYSTEM_TYPE.PLACIDUS,
  northernHouseSystem: HOUSE_SYSTEM_TYPE.EQUAL,
  northernSystemDegree: DEFAULT_NORTH_CHART_DEGREE,
});

const state = {
  locale: 'en',
  user: null,
  lastPassUpdate: null,
  loading: true,
  loggedIn: false,
  sidebarExpanded: true,
  banners: [],
  disabledBanners: [],
  paymentHistory: [],
  notifications: [],
  lastNotificationId: 0,
  settings: getDefaultUserSettings(),
  trialBannerOpened: true,
  orbPresets: [],
  carrotHash: '',
  tariffs: [],
  clickedModal: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
