<template>
  <v-app>
    <loader class="loader" v-if="loading" />
    <template v-else>
      <router-view />
      <cookie-banner />
    </template>
    <app-notification-container />
    <register-tooltip />
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import Loader from '@/elements/components/Loader';
import CookieBanner from '@/elements/containers/CookieBanner';
import { AppNotificationContainer } from '@/elements/components/notification';
import RegisterTooltip from '@/elements/components/RegisterTooltip';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    RegisterTooltip,
    AppNotificationContainer,
    Loader,
    CookieBanner,
  },
  computed: {
    ...mapState('UserModule', ['loading']),
    ...mapGetters('UserModule', ['currentUser', 'carrotHash']),
  },
  methods: {
    ...mapActions('UserModule', ['fetchUser', 'setLoggedIn', 'setLoading']),
  },
  async beforeMount() {
    if (sessionStorage.getItem('locale')) {
      this.$i18n.locale = sessionStorage.getItem('locale');
      axios.defaults.headers.common['Accept-Language'] = sessionStorage.getItem('locale');
      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute('lang', this.$i18n.locale);
      getHTMLTag.querySelector('head').querySelector('title').textContent = this.$i18n.t('appTitle');
      getHTMLTag.querySelector('head').querySelector('meta[name="description"]').content =
        this.$i18n.t('appDescription');
    } else {
      sessionStorage.setItem('locale', this.$i18n.locale);
    }
    try {
      await this.fetchUser();
      await this.setLoggedIn(true);
    } catch (error) {
      await this.setLoggedIn(false);
    } finally {
      this.setLoading(false);
      /*if (this.currentUser.id && this.carrotHash) {
        await this.setLoggedIn(true);
        // eslint-disable-next-line no-undef
        carrotquest.auth(this.currentUser.id.toString(), this.carrotHash);
      }*/
    }
  },
};
</script>
<style scoped lang="sass">
.loader
  position: fixed

::v-deep .v-application--wrap
  min-height: fit-content
  width: 100%
  display: block
  flex: initial
  flex-direction: initial
</style>
