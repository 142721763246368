<template>
  <div class="cookie-banner__wrapper" v-if="isOpen">
    <div class="cookie-banner">
      <p class="cookie-banner__text">
        {{ $t('cookies.use') }}
        <a :href="privacyPolicyLink" target="_blank">
          {{ $t('cookies.personalPolicy') }}
        </a>
        {{ $t('cookies.and') }}
        <a :href="termsLink" target="_blank">
          {{ $t('cookies.userAgreement') }}
        </a>
      </p>
      <CrossButton class="cookie-banner__close" @click="onClose" />
    </div>
  </div>
</template>

<script>
import CrossButton from '../components/CrossButton';
import { METRIC_NAMES, sendMetrics } from '@/utils/metrics/metrics';

const COOKIE_MAX_AGE = 60 * 60 * 24 * 365 * 5; // 5 years
const ACCEPTED_COOKIE_VALUE = 'cookie-accepted=true';

export default {
  name: 'CookieBanner',
  components: { CrossButton },
  data() {
    return {
      isOpen: false,
      privacyPolicyLink: process.env.VUE_APP_PRIVACY_POLICY_LINK,
      termsLink: 'https://astronova.io/terms',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.isCookieAccepted()) {
        return;
      }

      this.isOpen = true;

      sendMetrics(METRIC_NAMES.SHOW_COOKIES);
    },
    isCookieAccepted() {
      return document.cookie.indexOf(ACCEPTED_COOKIE_VALUE) > -1;
    },
    acceptCookie() {
      document.cookie = `${ACCEPTED_COOKIE_VALUE}; max-age=${COOKIE_MAX_AGE}`;
    },
    onClose() {
      this.acceptCookie();
      this.isOpen = false;
    },
  },
};
</script>

<style scoped lang="sass">
.cookie-banner
  display: flex
  background-color: #fff
  padding: base-unit(15) base-unit(40) base-unit(15) base-unit(15)
  border: base-unit(2) solid blue-color('primary')
  box-sizing: border-box
  border-radius: base-unit(10)

  &__wrapper
    position: fixed
    bottom: base-unit(20)
    left: base-unit(20)
    width: base-unit(500)
    z-index: 999

    +extra-small-devices
      width: 100%
      padding: base-unit(10)
      bottom: 0
      left: 0

  &__close
    position: absolute
    right: base-unit(15)
    top: base-unit(15)

  &__text
    margin: 0

    +extra-small-devices
      font-size: base-unit(13)
</style>
