export default {
  name: '',
  planetModels: null,
  cuspModels: null,
  params: {
    planets: [
      {
        name: 'SNode',
        position: 316.93627871785236,
        speed: -0.05295746970018901,
        relativeSpeed: -160,
      },
      {
        name: 'Pluto',
        position: 249.37891445711577,
        speed: -0.026994533406005394,
        relativeSpeed: -168,
      },
      {
        name: 'Mercury',
        position: 55.612848063072654,
        speed: 2.1547569119038954,
        relativeSpeed: 175,
      },
      {
        name: 'Moon',
        position: 145.7398446232556,
        speed: 14.705745397201142,
        relativeSpeed: 119,
      },
      {
        name: 'Lilith',
        position: 238.3694855784333,
        speed: 0.11201304411294885,
        relativeSpeed: 101,
      },
      {
        name: 'Venus',
        position: 104.43070803513397,
        speed: 1.08080956940981,
        relativeSpeed: 105,
      },
      {
        name: 'Neptune',
        position: 304.30911816103236,
        speed: -0.007862927408739603,
        relativeSpeed: -39,
      },
      {
        name: 'Chiron',
        position: 240.76671203928603,
        speed: -0.07454767074002253,
        relativeSpeed: -149,
      },
      {
        name: 'Sun',
        position: 60.35590304754911,
        speed: 0.9700294917490415,
        relativeSpeed: 98,
      },
      {
        name: 'Uranus',
        position: 316.79341796245535,
        speed: 6.966485363805506e-5,
        relativeSpeed: 0,
      },
      {
        name: 'NNode',
        position: 136.93627871785236,
        speed: -0.05295746970018901,
        relativeSpeed: -160,
      },
      {
        name: 'Saturn',
        position: 39.83062435814246,
        speed: 0.12337130364162331,
        relativeSpeed: 176,
      },
      {
        name: 'Mars',
        position: 205.64790560303902,
        speed: -0.1875039066614243,
        relativeSpeed: -33,
      },
      {
        name: 'Jupiter',
        position: 22.86773651205883,
        speed: 0.21624315167564367,
        relativeSpeed: 166,
      },
    ],
    cusps: [
      342.2055424024585, 41.34284784583762, 66.87304729410636, 84.17988053058383, 100.29626858562705,
      120.54491532733294, 162.2055424024585, 221.3428478458376, 246.87304729410636, 264.17988053058383,
      280.29626858562705, 300.54491532733294,
    ],
  },
  chains: [
    {
      Moon: {
        primary: true,
        relatedTo: 'Sun',
      },
      Mars: {
        primary: false,
        relatedTo: 'Venus',
      },
      Mercury: {
        primary: true,
        relatedTo: 'Venus',
      },
      Saturn: {
        primary: false,
        relatedTo: 'Venus',
      },
      Pluto: {
        primary: false,
        relatedTo: 'Jupiter',
      },
      Venus: {
        primary: true,
        relatedTo: 'Moon',
      },
      Sun: {
        primary: true,
        relatedTo: 'Mercury',
      },
      Jupiter: {
        primary: false,
        relatedTo: 'Mars',
      },
    },
    {
      Uranus: {
        primary: true,
        relatedTo: 'Uranus',
      },
      Neptune: {
        primary: false,
        relatedTo: 'Uranus',
      },
    },
  ],
  meta: {
    geo: {
      placeName: 'Ulm, Germany',
      longitude: 73.3645204,
      latitude: 54.9913545,
      longitudeInDegrees: '73°21′52″E',
      latitudeInDegrees: '54°59′29″N',
      coordinatesPresent: true,
      placeNamePresent: true,
    },
    date: '1999-05-22T03:45:00',
    dateShift: 7,
    fullname: null,
    age: 21,
    sign: 'Gemini',
    mapType: 'Placidus',
    gmtInfo: {
      autoGmt: true,
      gmt: 7,
      gmtFormatted: '07:00',
    },
  },
  cosmogram: false,
};
