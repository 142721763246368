import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
/*

const messages = {
  en: {
    message: {
      hello: 'hello world',
    },
  },
  ru: {
    message: {
      hello: 'Привет мир',
    },
  },
};

export const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'en',
  messages,
});
*/

/*
function loadLocaleMessages() {
  const locales = -require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_,\s]+)\.$/i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locale(key);
    }
  });
  return messages;
}

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
});
*/

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

const dateTimeFormats = {
  'de-DE': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'ru-RU': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
};

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'de',
  messages: loadLocaleMessages(),
  dateTimeFormats,
});
