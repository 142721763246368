import Vue from 'vue';
import debounce from 'lodash/debounce';

const LISTENER_PROP_NAME = 'scrollListener';
const DEBOUNCE_VALUE_MS = 500;

const onScroll = (scrollContainer, callback) =>
  debounce(async function () {
    const isBottomOfContainer =
      scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight;

    if (isBottomOfContainer && callback) {
      callback();
    }
  }, DEBOUNCE_VALUE_MS);

Vue.directive('infinite-scroll', {
  bind(el, binding) {
    el[LISTENER_PROP_NAME] = onScroll(el, binding.value);
    el.addEventListener('scroll', el[LISTENER_PROP_NAME]);
  },
  unbind(el) {
    el.removeEventListener('scroll', el[LISTENER_PROP_NAME]);
  },
});
