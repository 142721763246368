import { ChartType } from 'astro-chart';
import defaultFirstPersonData from './default-first-person-data';

export default {
  chart: null,
  chartId: null,
  chartIdForRevert: null,
  chartsToDelete: [],
  chartType: ChartType.NATAL,
  mapIsLoading: false,
  mapName: '',
  requestParams: {
    filled: false, // заполнены ли или нет
    chartType: null,
    firstPersonData: {},
    secondPersonData: {},
  },
  firstPersonData: defaultFirstPersonData,
  secondPersonData: {
    name: '',
    params: {
      planets: [],
      cusps: [],
    },
    meta: null,
    chains: null,
    cosmogram: false,

    planetModels: null,
    cuspModels: null,
  },
  paramsToSave: {},
  doubleMapDetails: {},
  strongPlanets: [],
  aspectsConfigurations: [],
  aspectModels: [],
  houseAspectModels: [],
  statusAssessment: {},
  aspectsInterpretation: [],
  houseInterpretations: [],
  signInterpretations: [],
  planetsInterpretation: null,
  currentInterpretation: '',

  chartHistoryItems: {},
  chartHistoryIds: [],
  chartHistoryPageNumber: 0,
  chartHistoryNoMore: false,

  errorText: '',

  drawOnePartOfMap: false,
  mapPartToDraw: '',
  dataForPartialMapRender: null,
  firstMapPartIsOn: true,
  secondMapPartIsOn: true,
  secondBirthPlaceParams: null,
  zoomValue: 0,
  orbsScale: 1,
};
