import axios from 'axios';
import { i18n } from '../../../plugins/i18n';
import { INTERPRETATION_TYPE } from '@/types';
import {
  makeApiRequestFromRequestParams,
  makeChartParamsFromApiResponse,
  makeRequestParamsFromApiResponse,
} from '@/utils/chart-mapper';
import { parseHistoryList } from '@/utils/history';

const actions = {
  async createChart({ state, commit, dispatch }, requestParams) {
    let errorText = '';
    commit('setCreateMapError', errorText);

    try {
      const requestData = makeApiRequestFromRequestParams(requestParams);
      const chartData = await axios.post('/astro/map', requestData);
      if (!localStorage.getItem('orbsScale') && state.chartId !== chartData.historyItemId) {
        dispatch('setOrbsScale');
      }
      await dispatch('setChartParams', chartData);
      await dispatch('getStatusAssessment');
      commit('setParamsForSavingChart', { request: requestData, response: chartData });
      commit('setChartIdForRevert', null);
    } catch (error) {
      console.log(error);
      if (error && error.data && error.data.message.includes('No location found for')) {
        errorText = 'Населенный пункт не найден. Попробуйте ввести координаты';
      } else {
        errorText = 'Попробуйте еще раз позже';
      }
      commit('setCreateMapError', errorText);
    }
  },
  async getChart({ state, dispatch }, id) {
    const data = await axios.get(`/astro/natalMap/${id}`);
    if (!localStorage.getItem('orbsScale') && state.chartId !== data.historyItemId) {
      dispatch('setOrbsScale');
    }
    await dispatch('setChartParams', data);
    await dispatch('getStatusAssessment');
  },
  async getDefaultChart({ dispatch, commit }) {
    const data = await axios.get('/astro/demoChart');
    const requestParams = makeRequestParamsFromApiResponse(data);
    const toSaveRequest = makeApiRequestFromRequestParams(requestParams);
    await dispatch('setRequestParams', requestParams);
    await dispatch('setChartParams', data);
    commit('setParamsForSavingChart', { request: toSaveRequest, response: data });
    await dispatch('getStatusAssessment');
  },
  updateChart({ state, commit, dispatch }, requestParams) {
    if (!state.chartId) {
      return dispatch('createChart', requestParams);
    }
    const requestData = makeApiRequestFromRequestParams(requestParams);

    return axios.patch(`/astro/map/${state.chartId}`, requestData).then(async (data) => {
      await dispatch('setChartParams', data);
      await dispatch('getStatusAssessment');
      commit('updateChartHistory', [makeRequestParamsFromApiResponse(data)]);
    });
  },
  async saveChart({ state, commit }, mapName) {
    mapName && commit('setChartName', mapName);
    const { mapId } = await axios.put('/astro/map', state.paramsToSave);
    mapId && commit('setChartId', mapId);
    commit('setFilledRequestParamsToDefault');
  },
  async deleteChart({ state, commit }) {
    await axios.delete(`/astro/map/${state.chartId}`);
    commit('removeChartHistoryItem', state.chartId);
    commit('setChartIdForRevert', state.chartId);
    commit('setChartId', null);
    commit('setFilledRequestParamsToDefault');
  },
  async revertChart({ state, commit }) {
    await axios.put(`/astro/map/${state.chartIdForRevert}`);
    commit('setChartId', state.chartIdForRevert);
  },
  setChartId({ commit }, id) {
    commit('setChartId', id);
  },
  setChartIdForRevert({ commit }, id) {
    commit('setChartIdForRevert', id);
  },
  getChartHistory({ commit }, { number = 0, size, sort, order } = {}) {
    return axios
      .get('/astro/natalMap', {
        params: {
          number,
          size,
          sort,
          order,
        },
      })
      .then((data) => {
        commit('addChartHistory', parseHistoryList(data.history));
        !data.history.length && commit('setChartHistoryNoMoreValue', true);
      });
  },

  sortChart({ commit }, { number = 0, size, sort, order } = {}) {
    return axios
      .get('/astro/natalMap', {
        params: {
          number,
          size,
          sort,
          order,
        },
      })
      .then((data) => {
        commit('removeChartHistory');
        commit('addChartHistory', parseHistoryList(data.history));
      });
  },
  removeChartHistory({ commit }) {
    commit('removeChartHistory');
  },
  getCities(_store, name) {
    let lang = i18n.locale;
    return axios.get(`/astro/getPlaceAutocomplete?input=${name}&locale=${lang}`);
  },
  async getPlanetsForce({ state, commit, getters }) {
    if (getters.isDoubleChart) {
      return commit('setStrongPlanets', []);
    }

    const aspectConfigs = await axios.post('/astro/aspectConfigs', getters.aspectsList);
    commit('setAspectsConfigurations', aspectConfigs.configurations);

    const result = await axios.post('/astro/planetForce', {
      cusps: state.firstPersonData.params.cusps,
      aspects: getters.aspectsList,
      planets: getters.planetsPosition,
      aspectConfigs: aspectConfigs.configurations,
      meta: state.firstPersonData.meta,
    });
    commit('setStrongPlanets', result.planets);
  },
  setRequestParams({ commit }, data) {
    commit('setRequestParams', data);
  },
  setMapIsLoading({ commit }, value) {
    commit('setMapIsLoading', value);
  },
  setChartParams({ commit }, data) {
    commit('setChartParams', makeChartParamsFromApiResponse(data));
  },
  setFilledRequestParamsToDefault({ commit }) {
    commit('setFilledRequestParamsToDefault');
  },
  setPlanetModels({ commit }, data) {
    commit('setPlanetModels', data);
  },
  setCuspModels({ commit }, data) {
    commit('setCuspModels', data);
  },
  setAspectModels({ commit, dispatch }, data) {
    commit('setAspectModels', data);
    if (process.env.VUE_APP_SHOW_STRONG_OBJECTS) {
      dispatch('getPlanetsForce');
    }
    dispatch('getAspectsInterpretation');
    dispatch('getSignAndHouseInterpretation');
  },
  setHouseAspectModels({ commit }, data) {
    commit('setHouseAspectModels', data);
  },
  async swapCharts({ state, commit, dispatch }) {
    await dispatch('setMapIsLoading', true);
    commit('swapCharts');
    await dispatch('setMapIsLoading', false);
    if (state.drawOnePartOfMap) {
      await dispatch('drawOnlyMapPart', state.mapPartToDraw);
    }
  },
  resetError({ commit }) {
    commit('setCreateMapError', '');
  },
  queueDeleteHistoryItem(context, chart) {
    context.commit('removeChartHistoryItem', chart.chartId);
    context.commit('addHistoryItemToDelete', chart);
  },
  async totalDeleteHistoryItem(context, chart) {
    await axios.delete(`/astro/map/${chart.chartId}`);
    context.commit('removeHistoryItemToDelete', chart);
  },
  revertDeleteHistoryItem(context, chart) {
    context.commit('removeHistoryItemToDelete', chart);
    context.commit('addChartHistoryItem', chart);
  },
  setChart({ commit }, chart) {
    commit('setChart', chart);
  },
  async getStatusAssessment({ state, commit }) {
    const result = await axios.post('/astro/statusAssessment', state.firstPersonData.meta);
    commit('setStatusAssessment', result);
  },
  async getAspectsInterpretation({ commit, getters, rootGetters }) {
    if (rootGetters['UserModule/isLoggedIn']) {
      try {
        const result = await axios.post('/astro/aspectInterpretation', {
          aspects: getters.aspectsList,
        });
        commit('setAspectsInterpretation', result.interpretations);
      } catch (err) {
        commit('setAspectsInterpretation', []);
      }
    }
  },
  showInterpretation({ state, commit }, model) {
    const { type } = model;
    let result = {};

    switch (type) {
      case INTERPRETATION_TYPE.ASPECT:
        result =
          state.aspectsInterpretation.length &&
          state.aspectsInterpretation.find(
            (el) =>
              el.aspectInfo.firstPlanetName === model.fromSubject.name &&
              el.aspectInfo.secondPlanetName === model.toSubject.name,
          );
        break;
      case INTERPRETATION_TYPE.ZODIAC:
        result = state.signInterpretations.length && state.signInterpretations.find((el) => el.sign === model.name);
        break;
      case INTERPRETATION_TYPE.CUSP:
        result =
          state.houseInterpretations.length &&
          state.houseInterpretations.find((el) => el.houseNumber === model.index + 1);
        break;
      case INTERPRETATION_TYPE.PLANET:
        if (!state.planetsInterpretation) return;
        result.interpretation = state.planetsInterpretation[model.name]
          ? state.planetsInterpretation[model.name]
          : null;
        break;
      default:
        break;
    }

    const text = (result && result.interpretation) || null;

    commit('setCurrentInterpretation', { type, text });
  },
  closeInterpretation({ state, commit }) {
    if (state.chart) {
      state.chart.removeSelection();
    }
    commit('setCurrentInterpretation', '');
  },
  async getSignAndHouseInterpretation({ commit, rootGetters }) {
    if (rootGetters['UserModule/isLoggedIn']) {
      try {
        const { houseInterpretations, signInterpretations } = await axios.get('/astro/signAndHouseInterpretation');

        houseInterpretations && commit('setHouseInterpretations', houseInterpretations);
        signInterpretations && commit('setSignInterpretations', signInterpretations);
      } catch (err) {
        commit('setHouseInterpretations', []);
        commit('setSignInterpretations', []);
      }
    }
  },
  async setRequestParamsHouseSystem({ state, dispatch }, value) {
    await dispatch('setRequestParams', {
      ...state.requestParams,
      firstPersonData: {
        ...state.requestParams.firstPersonData,
        autoHouse: false,
        houseSystem: value,
      },
    });
  },
  async setHouseSystem({ state, rootState, dispatch }, houseSystem) {
    const newRequestParams = {
      ...state.requestParams,
      firstPersonData: {
        ...state.requestParams.firstPersonData,
        autoHouse: houseSystem === 'auto',
        houseSystem: houseSystem === 'auto' ? rootState.UserModule.settings.houseSystem : houseSystem,
      },
    };

    await dispatch('setMapIsLoading', true);
    await dispatch('setRequestParams', newRequestParams);
    await dispatch('updateChart', newRequestParams);
    dispatch('setZoomValue');
    await dispatch('setMapIsLoading', false);
  },
  async setDateTime({ state, dispatch }, { person, value }) {
    const newRequestParams = {
      ...state.requestParams,
      [person]: {
        ...state.requestParams[person],
        ...value,
      },
    };

    await dispatch('setMapIsLoading', true);
    await dispatch('setRequestParams', newRequestParams);
    await dispatch('updateChart', newRequestParams);
    dispatch('setZoomValue');
    await dispatch('setMapIsLoading', false);
  },
  async setPersonBirthPlace({ state, dispatch }, { place, personKey }) {
    let newRequestParams = { ...state.requestParams };
    if (place) {
      newRequestParams = {
        ...state.requestParams,
        [personKey]: {
          ...state.requestParams[personKey],
          place,
        },
      };
      delete newRequestParams[personKey].long;
      delete newRequestParams[personKey].lat;
    }

    await dispatch('setMapIsLoading', true);
    await dispatch('setRequestParams', newRequestParams);
    await dispatch('updateChart', newRequestParams);
    dispatch('setZoomValue');
    await dispatch('setMapIsLoading', false);
  },
  async loadChart({ dispatch }, requestParams) {
    await dispatch('setMapIsLoading', true);
    await dispatch('setRequestParams', requestParams);
    await dispatch('getChart', requestParams.chartId);
    await dispatch('setMapIsLoading', false);
  },
  async editChart({ dispatch }, requestParams) {
    await dispatch('setMapIsLoading', true);
    await dispatch('setRequestParams', requestParams);
    await dispatch('updateChart', requestParams);
    await dispatch('setMapIsLoading', false);
  },
  async addChart({ dispatch }, requestParams) {
    await dispatch('setMapIsLoading', true);
    await dispatch('setRequestParams', requestParams);
    await dispatch('createChart', requestParams);
    await dispatch('setMapIsLoading', false);
  },
  async addDefaultChart({ dispatch }) {
    await dispatch('setMapIsLoading', true);
    await dispatch('getDefaultChart');
    await dispatch('setMapIsLoading', false);
  },
  /** Set data to render only one part of a map */
  async drawOnlyMapPart({ commit, dispatch }, part) {
    commit('setDrawOnePartOfMap', true);
    await dispatch('setMapIsLoading', true);
    commit('setMapPartToDraw', part);
    await dispatch('setMapIsLoading', false);
  },
  /** Clear all data in store to render both parts of a map */
  resetMapDrawToFull({ commit }) {
    commit('setMapPartActivity', { part: 'first', activity: true });
    commit('setMapPartActivity', { part: 'second', activity: true });
    commit('setDrawOnePartOfMap', false);
    commit('setMapPartToDraw', '');
  },
  async toggleMap({ state, dispatch, commit }, index) {
    commit('toggleMapPartActivity', index);

    if (state.firstMapPartIsOn && state.secondMapPartIsOn) {
      dispatch('resetMapDrawToFull');
    } else {
      if (state.firstMapPartIsOn || state.secondMapPartIsOn) {
        const type = state.firstMapPartIsOn ? 'first' : 'second';
        await dispatch('drawOnlyMapPart', type);
      } else {
        const part = index === 'first' ? 'second' : 'first';
        commit('setMapPartActivity', { part, activity: true });
        await dispatch('drawOnlyMapPart', part);
      }
    }
  },
  setSecondBirthPlaceParams({ commit }, data) {
    commit('setSecondBirthPlaceParams', data);
  },
  clearSecondBirthPlaceParams({ commit }) {
    commit('clearSecondBirthPlaceParams');
  },
  async getPlaceTimezone(_, { time, placeName }) {
    const { timeShift } = await axios.get('/astro/timezone', { params: { time, placeName } });
    return timeShift;
  },
  async getPlaceInfo(_, { time, placeName, houseSystemConfig }) {
    let autoHouse = houseSystemConfig.auto;
    let houseSystem = houseSystemConfig.houseSystem;
    let data = {
      time: time,
      placeName: placeName,
      houseSystemConfig: {
        auto: autoHouse,
        houseSystem: houseSystem === 'auto' ? 'Placidus' : houseSystem,
      },
    };
    return await axios.post('/astro/placeInfo', data);
  },
  setDefaultChartHistoryPageNumber({ commit }) {
    commit('setDefaultChartHistoryPageNumber');
  },
  increaseChartHistoryPageNumber({ commit }) {
    commit('increaseChartHistoryPageNumber');
  },
  setZoomValue({ commit }, value = 0) {
    commit('setZoomValue', value);
  },
  setOrbsScale({ commit, dispatch }, value = 1) {
    commit('setOrbsScale', value);
    dispatch('setZoomValue');
  },
};

export default actions;
