<template>
  <div class="notification-container">
    <transition-group name="list" tag="div">
      <div
        class="item-wrapper"
        v-for="(notification, index) in notifications"
        :key="notification[idProp]"
        :style="inlineStyles(index + 1)"
      >
        <slot v-bind:notification="notification"></slot>
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  name: 'NotificationContainer',
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    idProp: {
      type: String,
      default: 'id',
    },
  },
  computed: {
    inlineStyles() {
      return (index) => {
        const percent = (index / this.notifications.length).toFixed(2);
        return {
          '--bg-opacity': percent,
        };
      };
    },
  },
};
</script>
<style lang="sass" scoped>
.notification-container
  position: fixed
  bottom: base-unit(20)
  left: 0
  display: flex
  align-items: center
  flex-direction: column
  z-index: 9999
  width: 90%
  margin-left: 50%
  transform: translateX(-50%)

.item-wrapper
  background-color: white
  margin-bottom: base-unit(8)
  border-radius: base-unit(4)

  &:last-child
    margin-bottom: 0

.list-enter-active, .list-leave-active
  transition: all 1s

.list-enter, .list-leave-to
  opacity: 0
  transform: translateY(30px)
</style>
