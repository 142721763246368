import { TARIFF_PLAN } from '@/types';

const TARIFF_PLAN_MAP = {
  [TARIFF_PLAN.FREE]: [TARIFF_PLAN.FREE],
  [TARIFF_PLAN.PREMIUM]: [TARIFF_PLAN.FREE, TARIFF_PLAN.PREMIUM],
  [TARIFF_PLAN.ADMIN]: [TARIFF_PLAN.FREE, TARIFF_PLAN.PREMIUM, TARIFF_PLAN.ADMIN],
  [TARIFF_PLAN.TRIAL]: [TARIFF_PLAN.FREE, TARIFF_PLAN.PREMIUM],
  [TARIFF_PLAN.USER_TRIAL]: [TARIFF_PLAN.FREE, TARIFF_PLAN.PREMIUM, TARIFF_PLAN.USER_TRIAL],
};

export const isAllowedForUser = (userTariffPlan, requiredTariffPlan) => {
  return TARIFF_PLAN_MAP[userTariffPlan].includes(requiredTariffPlan);
};
