import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import * as modules from '@/store/modules';

Vue.use(Vuex);

const persistedState = createPersistedState({
  paths: ['UserModule.disabledBanners', 'UserModule.settings'],
});

const store = new Vuex.Store({
  modules,
  plugins: [persistedState],
});

export default store;
