import moment from 'moment';
import { ChartType } from 'astro-chart';
import { i18n } from '../plugins/i18n';

export const makeApiRequestFromRequestParams = (requestParams) => {
  const makeChartParams = (chartRequestParams) => {
    const { date, time, place, lat, long, noSave, mapType, cosmogram, gmtVal, autoGmt, autoHouse, houseSystem } =
      chartRequestParams;

    return {
      noSave,
      cosmogram,
      eventInfo: {
        event: moment.utc(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'),
        placeInfo: {
          placeName: place || undefined,
          longitude: long || undefined,
          latitude: lat || undefined,
          placeNamePresent: Boolean(place),
          coordinatesPresent: Boolean(long && lat),
        },
        gmtInfo: {
          autoGmt: autoGmt || autoGmt === undefined ? true : false,
          gmt: autoGmt || autoGmt === undefined ? 0 : gmtVal,
        },
        houseSystemConfig: {
          auto: autoHouse || (autoHouse === undefined && houseSystem === undefined) ? true : false,
          houseSystem: houseSystem === 'auto' ? 'Placidus' : houseSystem,
          //houseSystem === 'auto' ? 'Placidus' : houseSystem || houseSystem === undefined ? 'Placidus' : houseSystem,
        },
        mapType,
      },
    };
  };

  if (requestParams.chartType === ChartType.NATAL) {
    let mapName;
    if (sessionStorage.getItem('mapName') && !requestParams.mapName) {
      mapName = sessionStorage.getItem('mapName');
    } else {
      mapName = requestParams.mapName;
    }
    return {
      mapName: mapName,
      ...makeChartParams({
        ...requestParams.firstPersonData,
        noSave: requestParams.noSave,
      }),
    };
  } else {
    return {
      type: requestParams.chartType,
      mapName: requestParams.mapName,
      events: [
        makeChartParams({
          ...requestParams.firstPersonData,
          noSave: requestParams.noSave,
        }),
        makeChartParams({
          ...requestParams.secondPersonData,
          noSave: requestParams.noSave,
        }),
      ],
    };
  }
};

export const makeRequestParamsFromApiRequest = (apiParams) => {
  const { eventInfo, cosmogram = false, fullName, noSave = false, mapName } = apiParams;
  const { placeInfo, mapType, houseSystemConfig } = eventInfo;

  console.log(apiParams);

  const chartFullDate = moment.utc(eventInfo.event);

  /*if (i18n.locale === 'en') {
    date = chartFullDate.format('DD.MM.YYYY');
  } else {
    date = chartFullDate.format('MM.DD.YYYY');
  }*/
  return {
    noSave,
    cosmogram,
    mapType,
    fullName,
    mapName,
    houseSystem: houseSystemConfig?.houseSystem,
    date: chartFullDate.format('DD.MM.YYYY'),
    time: chartFullDate.format('HH:mm:ss'),
    place: placeInfo.placeName,
    long: placeInfo.longitude,
    lat: placeInfo.latitude,
  };
};

export const makeRequestParamsFromApiResponse = (apiResponse) => {
  const isDoubleChart = apiResponse.type !== ChartType.NATAL;

  const makeChartParams = ({ cosmogram, meta }) => {
    const chartFullDate = moment.utc(meta.date);
    return {
      cosmogram,
      mapType: meta.mapType,
      houseSystem: meta.houseSystemConfig.houseSystem,
      fullName: meta.fullName,
      date: chartFullDate.format('DD.MM.YYYY'),
      time: chartFullDate.format('HH:mm:ss'),
      place: meta.geo.placeName,
      long: meta.geo.longitude,
      lat: meta.geo.latitude,
    };
  };

  return {
    chartId: apiResponse.historyItemId,
    chartType: apiResponse.type,
    mapName: apiResponse.mapName,
    noSave: apiResponse.noSave,
    firstPersonData: makeChartParams(isDoubleChart ? apiResponse.maps[0] : apiResponse),
    secondPersonData: isDoubleChart ? makeChartParams(apiResponse.maps[1]) : undefined,
  };
};

export const makeChartParamsFromApiResponse = (apiResponse) => {
  const isDoubleChart = apiResponse.type !== ChartType.NATAL;
  return {
    firstPersonData: isDoubleChart ? apiResponse.maps[0] : apiResponse,
    secondPersonData: isDoubleChart ? apiResponse.maps[1] : undefined,
    id: apiResponse.historyItemId,
    chartType: apiResponse.type,
    mapName: apiResponse.mapName,
    doubleMapDetails: apiResponse.doubleMapDetails,
    planetsInterpretation:
      apiResponse && apiResponse.maps ? apiResponse.maps[0].planetsInterpretation : apiResponse.planetsInterpretation,
  };
};
