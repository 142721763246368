import Vue from 'vue';

import { getChartName } from './utils/chart-name';
import { PERSON } from '@/types';

const setPersonData = (store, { firstPersonData, secondPersonData }, property) => {
  if (firstPersonData) {
    Vue.set(store.firstPersonData, property, firstPersonData);
  }

  if (secondPersonData) {
    Vue.set(store.secondPersonData, property, secondPersonData);
  }
};

const mutations = {
  setRequestParams(store, data) {
    store.requestParams = {
      filled: true,
      ...data,
    };
  },
  setFilledRequestParamsToDefault(store) {
    store.requestParams.filled = false;
  },
  setChartParams(
    state,
    { id, chartType, mapName, firstPersonData, secondPersonData, doubleMapDetails, planetsInterpretation } = {},
  ) {
    const setParams = (person, { planets, cusps, chains, meta, cosmogram }) => {
      Vue.set(state, person, {
        name: getChartName(chartType, person),
        planetModels: state[person].planetModels,
        cuspModels: state[person].cuspModels,
        params: {
          planets,
          cusps,
        },
        chains,
        meta,
        cosmogram,
      });
    };

    if (firstPersonData) {
      setParams(PERSON.FIRST, firstPersonData);
    }

    if (secondPersonData) {
      setParams(PERSON.SECOND, secondPersonData);
    }

    state.doubleMapDetails = doubleMapDetails || {};
    state.chartType = chartType;
    state.chartId = id;
    state.mapName = mapName;
    state.planetsInterpretation = planetsInterpretation;
  },
  setParamsForSavingChart(state, { request, response }) {
    state.paramsToSave = { request, response };
  },
  setChartName(state, mapName) {
    if (state.paramsToSave.response && mapName) {
      state.paramsToSave.request.mapName = mapName;
      state.paramsToSave.response.mapName = mapName;
    }

    state.mapName = mapName;
  },
  setChartId(state, id) {
    state.chartId = id;
  },
  setChartIdForRevert(state, id) {
    state.chartIdForRevert = id;
  },
  setPlanetModels(state, data) {
    setPersonData(state, data, 'planetModels');
  },
  setCuspModels(state, data) {
    setPersonData(state, data, 'cuspModels');
  },
  setAspectModels(state, data) {
    state.aspectModels = data;
  },
  setHouseAspectModels(state, data) {
    state.houseAspectModels = data;
  },
  setMapIsLoading(state, value) {
    state.mapIsLoading = value;
  },
  setStrongPlanets(state, planets) {
    state.strongPlanets = [...planets];
  },
  setAspectsConfigurations(state, configs) {
    state.aspectsConfigurations = [...configs];
  },
  swapCharts(state) {
    const tmpData = state.firstPersonData;
    const tmpParams = state.requestParams.firstPersonData;

    Vue.set(state.requestParams, PERSON.FIRST, state.requestParams.secondPersonData);
    Vue.set(state.requestParams, PERSON.SECOND, tmpParams);

    Vue.set(state, PERSON.FIRST, state.secondPersonData);
    Vue.set(state, PERSON.SECOND, tmpData);
  },

  addChartHistory(state, history = []) {
    const ids = [...state.chartHistoryIds, ...history.map(({ chartId }) => chartId)];
    const uniqueIds = new Set(ids);
    state.chartHistoryIds = [...uniqueIds];

    history.map((chart) => {
      Vue.set(state.chartHistoryItems, chart.chartId, chart);
    });
  },
  updateChartHistory(state, updatedItems) {
    updatedItems.forEach((historyItem) => {
      Vue.set(state.chartHistoryItems, historyItem.chartId, historyItem);
    });
  },
  removeChartHistory(state) {
    state.chartHistoryIds = [];
    Vue.set(state, 'chartHistoryItems', {});
  },
  removeChartHistoryItem(state, id) {
    delete state.chartHistoryItems[id];
    const index = state.chartHistoryIds.indexOf(id);
    if (index > -1) {
      state.chartHistoryIds.splice(index, 1);
    }
  },
  addHistoryItemToDelete(state, chart) {
    state.chartsToDelete.push(chart);
    if (state.chartsToDelete.length > 5) {
      state.chartsToDelete.shift();
    }
  },
  removeHistoryItemToDelete(state, chart) {
    const index = state.chartsToDelete.findIndex((el) => el.chartId === chart.chartId);
    if (index > -1) {
      state.chartsToDelete.splice(index, 1);
    }
  },
  addChartHistoryItem(state, chart) {
    state.chartHistoryIds.push(chart.chartId);
    Vue.set(state.chartHistoryItems, chart.chartId, chart);
  },
  setCreateMapError(state, errorText) {
    state.errorText = errorText;
  },
  setChart(state, chart) {
    state.chart = chart;
  },
  setStatusAssessment(state, data) {
    state.statusAssessment = data;
  },
  setAspectsInterpretation(state, data) {
    state.aspectsInterpretation = data;
  },
  setHouseInterpretations(state, data) {
    state.houseInterpretations = data;
  },
  setSignInterpretations(state, data) {
    state.signInterpretations = data;
  },
  setCurrentInterpretation(state, interpretation) {
    state.currentInterpretation = interpretation;
  },
  /** Sets that we are drawing only one part of a map */
  setDrawOnePartOfMap(state, val) {
    state.drawOnePartOfMap = val;
  },
  /** Sets what map part we are rendering - first, second or whole */
  setMapPartToDraw(state, part) {
    if (part) {
      state.mapPartToDraw = part;
      state.dataForPartialMapRender = state[`${part}PersonData`];
    } else {
      state.mapPartToDraw = '';
      state.dataForPartialMapRender = null;
    }
  },
  setMapPartActivity(state, { part, activity }) {
    state[`${part}MapPartIsOn`] = activity;
  },
  toggleMapPartActivity(state, part) {
    state[`${part}MapPartIsOn`] = !state[`${part}MapPartIsOn`];
  },
  setSecondBirthPlaceParams(state, data) {
    if (data && data.secondPersonData) {
      const {
        secondPersonData: { place, lat, long },
      } = data;

      state.secondBirthPlaceParams = { place, lat, long };
    }
  },
  clearSecondBirthPlaceParams(state) {
    state.secondBirthPlaceParams = null;
  },
  setDefaultChartHistoryPageNumber(state) {
    state.chartHistoryPageNumber = 0;
  },
  increaseChartHistoryPageNumber(state) {
    state.chartHistoryPageNumber += 1;
  },
  setChartHistoryNoMoreValue(state, value) {
    state.chartHistoryNoMore = value;
  },
  setZoomValue(state, value) {
    state.zoomValue = value;
  },
  setOrbsScale(state, value) {
    state.orbsScale = value;
  },
};

export default mutations;
