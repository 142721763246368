import { ChartType } from 'astro-chart';

import { makeRequestParamsFromApiRequest } from '@/utils/chart-mapper';

export const parseHistoryList = (rawHistoryList) => {
  return rawHistoryList.map(({ request, response, id, requestType, name }) => {
    const parsedRequest = JSON.parse(request);
    const parsedResponse = JSON.parse(response);

    if (requestType === ChartType.NATAL) {
      return {
        chartId: id,
        chartType: requestType,
        mapName: name,
        firstPersonData: makeRequestParamsFromApiRequest(parsedRequest),
        chartData: parsedResponse,
      };
    } else {
      return {
        chartId: id,
        chartType: requestType,
        mapName: name,
        firstPersonData: makeRequestParamsFromApiRequest(parsedRequest.events[0]),
        secondPersonData: makeRequestParamsFromApiRequest(parsedRequest.events[1]),
        chartData: parsedResponse,
      };
    }
  });
};
