<template>
  <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="12" height="12" rx="1.5" fill="white" stroke="#C4C4C4" />
  </svg>
</template>
<script>
export default {
  name: 'UncheckedIcon',
};
</script>
