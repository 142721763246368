<template>
  <button v-bind="$attrs" v-on="$listeners" type="button" class="action-view">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ActionView',
};
</script>

<style scoped lang="sass">
.action-view
  box-sizing: border-box
  padding: 0
  width: fit-content

  cursor: pointer

  color: #000
  border: none
  background-color: initial

  &:disabled
    pointer-events: none

    opacity: 0.65

  &:hover
    opacity: 0.8

  &:focus
    outline: none
</style>
