import Vue from 'vue';
import moment from 'moment';
import App from '@/App';
import store from '@/store';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import { isProduction } from '@/utils/common';
import { enableGoogleMetrics, enableYandexMetrics } from '@/utils/metrics';

import './plugins/vuemq';
import './plugins/confirm-dialog';
import './plugins/v-mask';
import './plugins/vuelidate';
import './axios';
import './style/application.sass';
import './directives/infinite-scroll';
import './mixins/access-mixin';
import { i18n } from '@/plugins/i18n';
import './plugins/cookies';

Vue.config.productionTip = !isProduction;

enableYandexMetrics();
enableGoogleMetrics();

Vue.mixin({
  computed: {
    isMobileScreen() {
      return this.$mq === 'sm' || this.$mq === 'md';
    },
    isDesktopScreen() {
      return this.$mq === 'lg' || this.$mq === 'hr';
    },
  },
});

Vue.filter('cutSeconds', (time) => {
  return moment(time, 'HH:mm').format('HH:mm');
});

new Vue({
  i18n,
  vuetify,
  router,
  store,
  created() {
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute('lang', i18n.locale);
  },
  render: (h) => h(App),
}).$mount('#app');
