<template>
  <div class="register_modal" v-if="clickedModal" @click="clickModal(false)">
    <div class="register_modal--inner">
      <button class="cross-button">
        <img
          :src="require('@/assets/icons/cross-grey.svg')"
          alt="cross button"
          width="10"
          height="10"
          class="cross-button__img"
        />
      </button>
      <div class="register_modal--text" v-html="$t('registerToolTip')"></div>
      <div class="register_modal--buttons">
        <AstroButton
          class="sign_in-btn"
          :title="$t('login.enter')"
          @click="$router.replace({ name: 'signIn' })"
          color="secondary"
        />
        <AstroButton
          class="sign_up-btn"
          :title="$t('login.signUp')"
          @click="$router.replace({ name: 'signUp' })"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AstroButton from '@/elements/components/AstroButton';
export default {
  name: 'RegisterTooltip',
  components: { AstroButton },
  props: {
    clicked: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('UserModule', ['clickedModal']),
  },
  methods: {
    ...mapActions('UserModule', ['clickModal']),
  },
};
</script>

<style lang="sass" scoped>
.register_modal
  position: fixed
  bottom: 0
  left: 0
  display: flex
  align-items: center
  flex-direction: column
  z-index: 101
  width: 100%
  height: 100%
  &:before
    content: ''
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    background: #333333
    opacity: 0.7

  &--inner
    max-width: 500px
    width: 90%
    height: auto
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05)
    border-radius: 4px
    background-color: #ffffff
    position: absolute
    top: 35%
    left: 50%
    transform: translate(-50%)
    z-index: 101
    padding: base-unit(30) base-unit(50)
    @media screen and (max-width: 570px)
      padding: base-unit(30) base-unit(10)

    .cross-button
      position: absolute
      top: 13px
      right: 13px
      height: 10px
      display: flex
      align-items: center
      justify-content: center

  &--text
    max-width: 330px
    margin: 0 auto
    display: block
    font-weight: 500
    font-size: 16px
    line-height: 19px
    color: #666666
    text-align: center
    span
      display: block
      margin-bottom: 5px

  &--buttons
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    column-gap: base-unit(10)
    margin-top: base-unit(20)
    @media screen and (max-width: 570px)
      flex-direction: column
      align-items: center
      row-gap: 20px
    .sign_up-btn, .sign_in-btn
      width: 48%
      @media screen and (max-width: 570px)
        width: 100%
</style>
