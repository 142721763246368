<template>
  <label class="text-field">
    {{ label }}
    <sup v-if="required">*</sup>
    <v-text-field dense outlined v-bind="attrs" v-on="$listeners" />
  </label>
</template>

<script>
export default {
  name: 'TextField',
  computed: {
    attrs() {
      // eslint-disable-next-line no-unused-vars
      const { label, ...attrs } = this.$attrs;

      return attrs;
    },
    label() {
      return this.$attrs.label;
    },
    required() {
      return this.$attrs.required;
    },
  },
};
</script>

<style lang="sass" scoped>
.text-field
  color: grey-color('500')
  line-height: base-unit(19)

  sup
    color: blue-color('primary')

  ::v-deep
    .v-input__slot
      fieldset
        height: base-unit(38)
        border-color: grey-color('75')

      .v-icon
        margin-top: base-unit(-6)

    .v-input--is-dirty
        fieldset
          border-color: grey-color('75')

        &:not(.v-input--is-focused)
          input
            color: grey-color('primary')


    .v-text-field--outlined.v-input--is-focused fieldset,
    .v-text-field--outlined.v-input--has-state fieldset
      border-width: base-unit(1)
    .v-input__control
      margin-top: base-unit(8)

    .v-text-field
      &__details
        .v-messages__message
          font-size: base-unit(12)
      &__slot
        height: base-unit(30)

    .v-input input
      height: base-unit(26)
</style>
