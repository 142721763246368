import state from '@/store/modules/chart/state';
import actions from '@/store/modules/chart/actions';
import mutations from '@/store/modules/chart/mutations';
import getters from '@/store/modules/chart/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
