import VueRouter from 'vue-router';
import Vue from 'vue';

import store from '@/store';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
});

const handleRouteChange = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.UserModule.loggedIn) {
      return next({
        name: 'signIn',
        query: { redirect: to.fullPath },
      });
    }

    return next();
  }

  next();
};

router.beforeEach((to, from, next) => {
  if (store.state.UserModule.loading) {
    const unwatch = store.watch(
      () => store.state.UserModule.loading,
      (isLoading) => {
        if (!isLoading) {
          unwatch();

          handleRouteChange(to, from, next);
        }
      },
    );
  } else {
    handleRouteChange(to, from, next);
  }
});

export default router;
