import moment from 'moment';
import { ChartType } from 'astro-chart';

const extractDateAndTime = (meta) => {
  const fullDate = (meta && moment(meta.date)) || moment();
  return {
    time: fullDate.format('HH:mm:ss'),
    date: fullDate.format('DD.MM.YYYY'),
    metaDate: meta.date,
  };
};

const getters = {
  meta({ firstPersonData, secondPersonData }) {
    let meta = null;

    if (firstPersonData.meta) {
      meta = {
        firstPersonData: {
          ...firstPersonData.meta,
          ...extractDateAndTime(firstPersonData.meta),
        },
      };
    }

    if (secondPersonData.meta) {
      meta = meta || {};
      meta.secondPersonData = {
        ...secondPersonData.meta,
        ...extractDateAndTime(secondPersonData.meta),
      };
    }

    return meta;
  },
  isCosmogram({ firstPersonData, temp, drawOnePartOfMap }) {
    if (drawOnePartOfMap) {
      return temp && temp.cosmogram;
    } else {
      return firstPersonData && firstPersonData.cosmogram;
    }
  },
  chartHistory({ chartHistoryItems, chartHistoryIds }) {
    return chartHistoryIds.map((id) => chartHistoryItems[id]);
  },
  currentAspectsConfig({ chartType, drawOnePartOfMap }, _getters, { UserModule }) {
    const type = drawOnePartOfMap ? ChartType.NATAL : chartType;
    return UserModule.settings.aspectsConfig[type];
  },
  currentOrbsConfig({ chartType }, _getters, { UserModule }) {
    return UserModule.settings.orbsConfig[chartType];
  },
  getChains(state) {
    return state.firstPersonData && state.firstPersonData.chains ? state.firstPersonData.chains : [];
  },
  showChains(_, { isDoubleChart }) {
    return !isDoubleChart;
  },
  showAspects({ firstPersonData }) {
    return !!firstPersonData.planetModels;
  },
  showCoords({ firstPersonData }) {
    return !!firstPersonData.planetModels;
  },
  showStrongObjects({ aspectModels }, { isDoubleChart }) {
    return aspectModels && !isDoubleChart;
  },
  showSkeleton({ mapIsLoading, chart }) {
    return mapIsLoading && (!chart || (chart && !chart.isBuilt));
  },
  planetsPosition(state) {
    const planets = {};
    Object.values(state.firstPersonData.params.planets).forEach((planet) => {
      planets[planet.name] = planet.position;
    });

    return planets;
  },
  aspectsList(state) {
    if (!state.aspectModels) return [];

    return state.aspectModels.map((model) => ({
      angle: model.angle,
      orb: model.orb,
      firstPlanetName: model.fromSubject.name,
      secondPlanetName: model.toSubject.name,
    }));
  },
  aspectModels({ aspectModels, houseAspectModels }) {
    return [...aspectModels, ...houseAspectModels];
  },
  isDoubleChart({ requestParams }) {
    return requestParams.chartType !== ChartType.NATAL;
  },
  houseSystem(state) {
    return state.firstPersonData.meta.mapType;
  },
  chartId(state) {
    return state.chartId;
  },
  mapName(state) {
    return state.mapName;
  },
  chartIdForRevert(state) {
    return state.chartIdForRevert;
  },
  secondBirthPlaceParams(state) {
    return state.secondBirthPlaceParams;
  },
  firstPersonDateShift(state) {
    return state.firstPersonData.meta && state.firstPersonData.meta.dateShift;
  },
  secondPersonDateShift(state) {
    return state.secondPersonData.meta && state.secondPersonData.meta.dateShift;
  },
  firstPersonCoordsInDegrees(state) {
    const latitudeInDegrees =
      state.firstPersonData.meta && state.firstPersonData.meta.geo.latitudeInDegrees.slice(0, -1);
    const longitudeInDegrees =
      state.firstPersonData.meta && state.firstPersonData.meta.geo.longitudeInDegrees.slice(0, -1);
    return { latitudeInDegrees, longitudeInDegrees };
  },
  secondPersonCoordsInDegrees(state) {
    const latitudeInDegrees =
      state.secondPersonData.meta && state.secondPersonData.meta.geo.latitudeInDegrees.slice(0, -1);
    const longitudeInDegrees =
      state.secondPersonData.meta && state.secondPersonData.meta.geo.longitudeInDegrees.slice(0, -1);
    return { latitudeInDegrees, longitudeInDegrees };
  },
  chartHistoryPageNumber(state) {
    return state.chartHistoryPageNumber;
  },
  chartHistoryNoMore(state) {
    return state.chartHistoryNoMore;
  },
  zoomValue(state) {
    return state.zoomValue;
  },
  orbsScale(state) {
    return state.orbsScale;
  },
};

export default getters;
