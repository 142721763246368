import { DEGREE_ASPECT_MAP, NAME_CUSP_NUMBER_MAP, NUMBER_CUSP_NAME_MAP } from '@/types';
import { ASPECT_DEFAULT_CONFIGURATION } from './aspects-defaults';

const transformApiAspectConfigToUIAspectConfig = (aspects) => {
  return aspects.reduce((acc, aspect) => {
    const name = DEGREE_ASPECT_MAP[aspect.angle];

    acc[name] = {
      name,
      angle: aspect.angle,
      active: aspect.enabled,
    };

    return acc;
  }, {});
};

const transformApiOrbsConfigToUIOrbsConfig = (orbsConfig) => {
  return orbsConfig.reduce((accChart, orb) => {
    const aspectableOrbsConfig = orb.angles;
    const aspectableName = NUMBER_CUSP_NAME_MAP[orb.planet] || orb.planet;

    accChart[aspectableName] = Object.keys(aspectableOrbsConfig).reduce((accPlanet, aspectDegree) => {
      const aspectOrb = aspectableOrbsConfig[aspectDegree];
      const aspectName = DEGREE_ASPECT_MAP[aspectDegree];

      accPlanet[aspectName] = aspectOrb;
      return accPlanet;
    }, {});
    return accChart;
  }, {});
};

const transformUIAspectConfigToApiAspectConfig = (aspectsConfig) => {
  return Object.values(aspectsConfig).map((config) => ({
    angle: config.angle,
    enabled: config.active,
  }));
};

const transformUIOrbsConfigToApiOrbsConfig = (orbsConfig) => {
  return Object.keys(orbsConfig).reduce((accChart, aspectable) => {
    const aspectableOrbsConfig = orbsConfig[aspectable];
    const aspectableName = NAME_CUSP_NUMBER_MAP[aspectable] || aspectable;

    accChart[aspectableName] = Object.keys(aspectableOrbsConfig).reduce((accPlanet, aspectName) => {
      const aspectOrb = aspectableOrbsConfig[aspectName];
      const { angle } = ASPECT_DEFAULT_CONFIGURATION[aspectName];

      accPlanet[angle] = aspectOrb;
      return accPlanet;
    }, {});
    return accChart;
  }, {});
};

export const transformApiConfigToLibConfig = (orbs) => {
  return orbs.reduce(
    (config, orb) => {
      config.orbsConfig[orb.mapType] = transformApiOrbsConfigToUIOrbsConfig(orb.settings);
      config.aspectsConfig[orb.mapType] = transformApiAspectConfigToUIAspectConfig(orb.aspectsInclusion);

      return config;
    },
    {
      orbsConfig: {},
      aspectsConfig: {},
    },
  );
};

export const transformUIConfigToApiConfig = (orbsConfig, aspectsConfig) => {
  return Object.keys(orbsConfig).reduce((accConfig, chartType) => {
    const chartOrbsConfig = orbsConfig[chartType];
    const chartAspectsConfig = aspectsConfig[chartType];

    accConfig[chartType] = {
      angles: transformUIOrbsConfigToApiOrbsConfig(chartOrbsConfig),
      aspectsInclusion: transformUIAspectConfigToApiAspectConfig(chartAspectsConfig),
    };

    return accConfig;
  }, {});
};

export const transformOrbsPresetConfigToApiConfig = (orbsConfig, aspectsConfig) => {
  return Object.keys(orbsConfig).map((mapType) => {
    const chartOrbsConfig = orbsConfig[mapType];
    const chartAspectsConfig = aspectsConfig[mapType];

    const planetConfig = transformUIOrbsConfigToApiOrbsConfig(chartOrbsConfig);

    const settings = Object.keys(planetConfig).map((planet) => {
      const angles = planetConfig[planet];

      return { planet, angles };
    });

    const aspectsInclusion = transformUIAspectConfigToApiAspectConfig(chartAspectsConfig);

    return { mapType, settings, aspectsInclusion };
  });
};
