<template>
  <div class="notification-item">
    <img v-if="img" :src="require(`@/assets/icons/${img}.svg`)" alt="" />
    <div class="notification-item__text">
      <slot name="text"></slot>
    </div>
    <slot name="button"></slot>
  </div>
</template>

<script>
export default {
  name: 'NotificationItem',
  props: {
    interval: {
      type: Number,
      default: 3000,
    },
    img: {
      type: String,
    },
  },
  data() {
    return {
      timeoutId: -1,
    };
  },
  methods: {
    init() {
      this.timeoutId = setTimeout(() => this.$emit('timeout'), this.interval);
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    if (this.timeoutId !== -1) {
      clearTimeout(this.timeoutId);
    }
  },
};
</script>

<style lang="sass" scoped>
.notification-item
  display: grid
  grid-template-columns: 1fr base-unit(40)
  +astro-box-shadow
  border-radius: base-unit(4)
  color: white
  margin: 0 auto
  width: fit-content
  +extra-small-devices
    display: flex
    align-content: center
    justify-content: space-between
    padding: base-unit(10) 0
  +medium-devices
    display: flex
    align-content: center
    justify-content: space-between

  &__text
    color: grey-color('500')
    padding: 0 base-unit(10)
    align-self: center
    font-size: $base-font-size
    +extra-small-devices
      padding-left: 0
      padding-right: 0
      line-height: base-unit(19)
    +medium-devices
      padding: 0 base-unit(20)

  button
    color: blue-color('primary')
    &:hover
      opacity: 0.8
    &:focus
      outline: none
  img
    padding-left: base-unit(20)
    +extra-small-devices
      width: base-unit(32)
      height: base-unit(38)
      padding-left: 0
      margin-left: base-unit(10)
      margin-right: base-unit(6)
  .cross-button::v-deep
    +extra-small-devices
      margin: 0 base-unit(10)
      padding: 0
    .cross-button__img
      margin: base-unit(3) 0 0
</style>
