import Vue from 'vue';
import Vuetify, {
  VForm,
  VProgressCircular,
  VTabsSlider,
  VTab,
  VTabs,
  VCheckbox,
  VRadio,
  VRadioGroup,
  VTooltip,
  VSelect,
  VTextField,
  VListItem,
  VListItemTitle,
  VAutocomplete,
  VApp,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VIcon,
} from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { CheckedIcon, UncheckedIcon } from '@/elements/components/check-box';
/*import { i18n } from './i18n';*/

Vue.use(Vuetify, {
  components: {
    VForm,
    VProgressCircular,
    VTabsSlider,
    VTab,
    VTabs,
    VCheckbox,
    VRadio,
    VRadioGroup,
    VTooltip,
    VSelect,
    VTextField,
    VListItem,
    VListItemTitle,
    VAutocomplete,
    VApp,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
  },
});

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      uncheckedCheckbox: {
        component: UncheckedIcon,
      },
      checkedCheckbox: {
        component: CheckedIcon,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#167ea2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  /*  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },*/
});
