const yandexMetricKey = process.env.VUE_APP_YANDEX_METRICS_API_KEY;

export const METRIC_NAMES = {
  ORBS_TABLE: 'orbs_table',
  ASPECTS_TABLE: 'asps_table',
  COORDS_TABLE: 'coords_table',
  NEW_CHART: 'new_chart',
  SHOW_COOKIES: 'show_cookies',
  TOOLTIP_ASPECT: 'tooltip_aspect',
  TOOLTIP_CUSP: 'tooltip_cusp',
  TOOLTIP_PLANET: 'tooltip_planet',
  TOOLTIP_ZODIAC: 'tooltip_zodiac',
  CHART_CREATED_WITH_MAP: 'chart_created_with_map',
  CHART_CREATED_WITH_SEARCH: 'chart_created_with_search',
  CHART_CREATED_WITH_COORDS: 'chart_created_with_coords',
};

export const sendMetrics = (metric) => {
  if (!window.ym) {
    return;
  }

  if (!Object.values(METRIC_NAMES).includes(metric)) {
    console.log(`Invalid metric name ${metric}`);
    return;
  }

  window.ym(yandexMetricKey, 'reachGoal', metric);
};
