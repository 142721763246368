import Vue from 'vue';
import ConfirmDialog from '@/elements/components/dialogs/ConfirmDialog';
import vuetify from '@/plugins/vuetify';

const ConfirmDialogPlugin = {
  install: (vue) => {
    function createConfirmDialog(text, positiveLabel, negativeLabel, fieldLabel, inputValue, action) {
      return new Promise((resolve) => {
        const dialog = new Vue({
          vuetify,
          data() {
            return {
              isSubmitting: false,
            };
          },
          methods: {
            closeHandler(result, value) {
              return async () => {
                result && action && (await this.onSubmit(value));
                resolve(result);
                dialog.$destroy();
                dialog.$el.remove();
              };
            },
            async onSubmit(result) {
              try {
                this.isSubmitting = true;
                await action(result);
              } catch (e) {
                console.log(e);
              } finally {
                this.isSubmitting = false;
              }
            },
          },
          render(createElement) {
            return createElement(ConfirmDialog, {
              props: {
                show: true,
                submitTitle: positiveLabel,
                rejectTitle: negativeLabel,
                inputTitle: fieldLabel,
                value: inputValue,
                isSubmitting: this.isSubmitting,
              },
              on: {
                submit: (value) => this.closeHandler(true, value)(),
                reject: this.closeHandler(false),
              },
              scopedSlots: {
                default: () => text,
              },
            });
          },
        }).$mount();
      });
    }

    vue.prototype.$showConfirmDialog = (text, submitLabel, rejectLabel, fieldLabel, inputValue, action = null) => {
      return createConfirmDialog(text, submitLabel, rejectLabel, fieldLabel, inputValue, action);
    };
  },
};

Vue.use(ConfirmDialogPlugin);
