/* eslint-disable no-undef */

export const enableGoogleMetrics = () => {
  if (process.env.VUE_APP_ENABLE_GOOGLE_METRICS !== 'true') {
    return;
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', process.env.VUE_APP_GOOGLE_METRICS_API_KEY);
};
