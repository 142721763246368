<template>
  <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="12" height="12" rx="1.5" fill="#167EA2" stroke="#167EA2" />
    <path d="M3 7L5 9L10.5 3" stroke="white" stroke-width="2" />
  </svg>
</template>
<script>
export default {
  name: 'CheckedIcon',
};
</script>
