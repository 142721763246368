<template>
  <v-checkbox
    validate-on-blur
    v-bind="$attrs"
    v-on="$listeners"
    dense
    background-color="'#212121'"
    :color="'#212121'"
    :on-icon="'$checkedCheckbox'"
    :off-icon="'$uncheckedCheckbox'"
  >
    <template v-slot:label>
      <slot></slot>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: 'CheckBox',
};
</script>

<style lang="sass">
.v-icon.v-icon
  color: blue-color('primary')

.v-input__slot
  align-items: initial

.v-input--selection-controls
  margin-top: 0 !important

.v-input--is-dirty:not(.v-input--is-focused) label
  opacity: 1
  color: grey-color('500')
  caret-color: grey-color('500')


.v-input:not(.v-input--is-dirty) rect
  stroke: grey-color('75')

.v-input--checkbox
  .v-input--selection-controls__input
    margin-top: base-unit(3)
</style>
