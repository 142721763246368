import axios from 'axios';
import { transformApiSettingsToUISettings, transformUISettingsToApiSettings } from './utils/settings';
import { transformOrbsPresetConfigToApiConfig } from './utils/orbs-config';
import { showPaymentForm } from '@/elements/pages/tariffs/payment-service';

const getPaymentHistory = async ({ number = 0, size }) => {
  const { payments } = await axios.get('/users/paymentHistory', {
    params: {
      number,
      pageSize: size,
    },
  });

  return payments;
};

const actions = {
  signIn({ commit }, data) {
    return axios.post('/login', data).then(() => {
      commit('setLoggedIn', true);
    });
  },
  signUp(_state, data) {
    return axios.post('/users/sign-up', data);
  },
  logOut({ commit }) {
    return axios.post('/logout').then(() => {
      commit('setLoggedIn', false);
      commit('setUser', null);
    });
  },
  async fetchUser({ commit, dispatch }) {
    const user = await axios.get('/users/me');
    commit('setUser', user);
    await dispatch('getSettings');
    await dispatch('getCarrotHash');
  },
  confirmEmail(_store, token) {
    return axios.get(`/users/verification?token=${token}`);
  },
  resetPassword(_store, email) {
    return axios.get(`/users/resetPassword?email=${email}`);
  },
  async updatePasswordMsg({ commit }) {
    const data = await axios.get('/users/password');
    /*const formatedDate = new Date(data.updateTime).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });*/
    commit('setUpdateMsg', data.updateTime);
  },
  updatePassword(_store, data) {
    return axios.post('/users/updatePassword', data);
  },
  changePassword(_store, data) {
    return axios.patch('/users/password', data);
  },
  changeEmail(_store, data) {
    return axios.patch('/users/email', data);
  },
  async addPaymentHistory({ commit }, data = {}) {
    commit('addPaymentHistory', await getPaymentHistory(data));
  },
  async setPaymentHistory({ commit }, data = {}) {
    commit('setPaymentHistory', await getPaymentHistory(data));
  },
  async cancelSubscription() {
    const { success, message } = await axios.delete('/payments/cancel');
    if (!success) {
      throw new Error(message);
    }
  },
  async setSubscription({ state }, payload) {
    const { plan, amount } = payload;
    await showPaymentForm({
      amount,
      email: state.user.email,
      currency: 'RUB',
      type: plan,
    });
  },
  async setStripeSubscription(_store, product) {
    const { paymentLink } = await axios.get(`/payments/stripeSubscription?product=${product}`);

    if (!paymentLink) return;

    const link = document.createElement('a');
    link.href = paymentLink;

    link.click();
  },
  async getSettings({ commit }) {
    const settings = await axios.get('/users/configuration');

    commit('updateChartSettings', transformApiSettingsToUISettings(settings));
  },

  setLoading({ commit }, value) {
    commit('setLoading', value);
  },
  setLoggedIn({ commit }, value) {
    commit('setLoggedIn', value);
  },
  addChartHistory({ commit }, value) {
    commit('addChartHistory', value);
  },
  setSidebarExpansion({ commit }, value) {
    commit('setSidebarExpansion', value);
  },
  getBanners({ commit }) {
    return axios.get('/banners/banner').then((data) => {
      commit('setBanners', data.banners);
    });
  },
  closeBanner({ commit }, bannerId) {
    commit('addDisabledBanner', bannerId);
  },
  showBanner({ commit }, bannerId) {
    commit('removeDisabledBanner', bannerId);
  },
  async updateChartSettings({ state, commit, dispatch }, settings) {
    const { presetType, orbsConfig, aspectsConfig } = settings;

    const presetSettings =
      presetType === 'CUSTOM'
        ? { type: presetType, orbs: transformOrbsPresetConfigToApiConfig(orbsConfig, aspectsConfig) }
        : state.orbPresets.find(({ type }) => type === presetType);

    if (state.loggedIn) {
      await axios.patch('/users/configuration', transformUISettingsToApiSettings({ ...settings, presetSettings }));
    }

    await dispatch('ChartModule/setRequestParamsHouseSystem', settings.houseSystem, {
      root: true,
    });

    commit('updateChartSettings', settings);
  },
  addNotification({ commit }, data) {
    commit('addNotification', data);
  },
  removeNotification({ commit }, notification) {
    commit('removeNotification', notification);
  },
  closeTrialBanner({ commit }) {
    commit('closeTrialBanner');
  },
  setCurrentLocale({ commit }, locale) {
    commit('setLocale', locale);
  },
  async getOrbPresets({ commit }) {
    const { presets } = await axios.get('/users/orbPresets');
    commit('setOrbPresets', presets);
  },
  async getCarrotHash({ commit }) {
    const { carrotHash } = await axios.get('/users/carrotHash');
    commit('setCarrotHash', carrotHash);
  },
  async getTariffsInfo({ commit }) {
    const { tariffs } = await axios.get('payments/tariffs');
    commit('setTariffsInfo', tariffs);
  },
  clickModal({ commit }, modalState) {
    commit('clickModal', modalState);
  },
};

export default actions;
