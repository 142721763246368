import { ChartType } from 'astro-chart';

import { ChartTypeTranslation, PERSON } from '@/types';

const PERSON_PARTNER_NAME_MAP = {
  [PERSON.FIRST]: 'Партнер 1',
  [PERSON.SECOND]: 'Партнер 2',
};

export const getChartName = (chartType, person) => {
  if (chartType === ChartType.SYNASTRY) {
    return PERSON_PARTNER_NAME_MAP[person];
  }

  if (person === PERSON.FIRST) {
    return ChartTypeTranslation[ChartType.NATAL];
  }

  return ChartTypeTranslation[chartType];
};
