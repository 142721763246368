<template>
  <ActionView v-on="$listeners" class="cross-button">
    <img :src="icon" alt="cross button" width="14" height="14" class="cross-button__img" />
  </ActionView>
</template>

<script>
import ActionView from '../atoms/ActionView';

export default {
  name: 'CrossButton',
  components: { ActionView },
  props: {
    color: {
      type: String,
      validator: function (value) {
        return ['blue', 'white', 'grey'].indexOf(value) !== -1;
      },
      default: 'blue',
    },
  },
  data() {
    return {
      path: {
        // eslint-disable-next-line no-undef
        blue: require('@/assets/icons/cross.svg'),
        // eslint-disable-next-line no-undef
        white: require('@/assets/icons/cross-white.svg'),
        // eslint-disable-next-line no-undef
        grey: require('@/assets/icons/cross-grey.svg'),
      },
    };
  },
  computed: {
    icon() {
      return this.path[this.color];
    },
  },
};
</script>

<style scoped lang="sass">
.cross-button
  display: flex
  width: base-unit(20)
  height: base-unit(20)

  &__img
    margin: auto
</style>
