import { TARIFF_PLAN } from '@/types';
import { i18n } from '@/plugins/i18n';
import moment from 'moment';

const getters = {
  isLoggedIn: ({ loggedIn }) => loggedIn,
  currentUser: ({ user }) => user,
  sidebarExpanded: (state) => state.sidebarExpanded,
  selectedTariffPlan: ({ user }) => (user && user.role) || TARIFF_PLAN.FREE,
  selectedTariffPlanTranslation: (_, { selectedTariffPlan }) => i18n.t([selectedTariffPlan]),
  tariffExpireDays: ({ user }) => user && user.tariffExpireDays,
  isTrialBannerOpened: (state, getters) => getters.selectedTariffPlan === TARIFF_PLAN.TRIAL && state.trialBannerOpened,
  nextPaymentDate: (state) =>
    state.user && state.user.nextPaymentDate && moment(state.user.nextPaymentDate).format('DD.MM.YYYY'),
  carrotHash: (state) => state.carrotHash,
  tariffs: (state) => state.tariffs,
  clickedModal: (state) => state.clickedModal,
};

export default getters;
