import { Aspect, ChartType } from 'astro-chart';
import _ from 'lodash';

import { CHART_ASPECTS_MAP } from '@/types';

export const ASPECT_DEFAULT_CONFIGURATION = {
  [Aspect.CONJUNCTION]: {
    name: Aspect.CONJUNCTION,
    active: true,
    angle: 0,
  },
  [Aspect.SEMI_SEXTIL]: {
    name: Aspect.SEMI_SEXTIL,
    active: true,
    angle: 30,
  },
  [Aspect.SEXTIL]: {
    name: Aspect.SEXTIL,
    active: true,
    angle: 60,
  },
  [Aspect.SQUARE]: {
    name: Aspect.SQUARE,
    active: true,
    angle: 90,
  },
  [Aspect.TRINE]: {
    name: Aspect.TRINE,
    active: true,
    angle: 120,
  },
  [Aspect.QUICONS]: {
    name: Aspect.QUICONS,
    active: true,
    angle: 150,
  },
  [Aspect.OPPOSITION]: {
    name: Aspect.OPPOSITION,
    active: true,
    angle: 180,
  },
};

const fillTable = (aspectList) =>
  aspectList.reduce((aspectAcc, aspectName) => {
    aspectAcc[aspectName] = _.cloneDeep(ASPECT_DEFAULT_CONFIGURATION[aspectName]);
    return aspectAcc;
  }, {});

export const initAspectsConfig = () => {
  return Object.values(ChartType).reduce((acc, chartType) => {
    acc[chartType] = fillTable(CHART_ASPECTS_MAP[chartType]);
    return acc;
  }, {});
};
