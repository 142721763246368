import { TARIFF_PLAN, TARIFF_PLAN_TRANSLATION } from '@/types';
import { i18n } from '../../../plugins/i18n';

const PAYMENT_SCRIPT_URL = 'https://widget.cloudpayments.ru/bundles/cloudpayments';

const SUBSCRIPTION_PAYMENT_TYPE_MAP = {
  [TARIFF_PLAN.FREE]: 'SUBSCRIPTION_STANDART',
  [TARIFF_PLAN.PREMIUM]: 'SUBSCRIPTION_PREMIUM',
};

export const addPaymentWidget = () => {
  const paymentWidgetScript = document.createElement('script');
  paymentWidgetScript.setAttribute('src', PAYMENT_SCRIPT_URL);
  document.head.appendChild(paymentWidgetScript);
};

export const showPaymentForm = (payment) => {
  if (!window.cp) {
    return;
  }

  const { email, amount, currency, type } = payment;
  return new Promise((resolve, reject) => {
    let lang;
    if (i18n.locale === 'en') {
      lang = 'en-US';
    } else if (i18n.locale === 'de') {
      lang = 'de-DE';
    }
    const widget = new window.cp.CloudPayments({ language: `${lang}` });

    const receipt = {
      Items: [
        {
          label: `Подписка ${TARIFF_PLAN_TRANSLATION[type]}`,
          price: amount,
          quantity: 1.0,
          amount,
          vat: 20, // Ставка НДС
          method: 0,
          object: 0,
        },
      ],
      email,
      isBso: false,
      amounts: {
        electronic: amount,
      },
    };

    const data = {
      CloudPayments: {
        CustomerReceipt: receipt,
        recurrent: {
          interval: 'Month',
          period: 1,
        },
      },
    };

    widget.pay(
      'auth',
      {
        publicId: process.env.VUE_APP_PAYMENT_PUBLIC_ID,
        description: SUBSCRIPTION_PAYMENT_TYPE_MAP[type],
        email,
        amount,
        currency,
        accountId: email,
        skin: 'modern',
        data: data,
      },
      {
        onSuccess(options) {
          resolve(options);
        },
        onFail(reason) {
          reject(reason);
        },
      },
    );
  });
};
