import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    exlg: 1280,
    hr: 1440,
  },
  defaultBreakpoint: 'sm',
});
