import { Planet, Aspect, Zodiac, ChartType, Cusp, ZodiacElement } from 'astro-chart';

/*
export const HOUSE_SYSTEM_TYPE = {
  PLACIDUS: 'Placidus',
  EQUAL: 'Equal',
  EQUAL_MC: 'EqualMC',
  KOCH: 'Koch',
};
*/

export const HOUSE_SYSTEM_TYPE = {
  Placidus: 'Placidus',
  Equal: 'Equal',
  EqualMC: 'EqualMC',
  Koch: 'Koch',
};

export const HOUSE_SYSTEM_TRANSLATION = {
  [HOUSE_SYSTEM_TYPE.Placidus]: 'Плацидус',
  [HOUSE_SYSTEM_TYPE.Equal]: 'Равнодомная от Asc',
  [HOUSE_SYSTEM_TYPE.EqualMC]: 'Равнодомная от MС',
  [HOUSE_SYSTEM_TYPE.Koch]: 'Кох',
};

export const ChartTypeTranslation = {
  [ChartType.NATAL]: 'NATAL',
  [ChartType.SYNASTRY]: 'SYNASTRY',
  [ChartType.SOLAR]: 'SOLAR',
  [ChartType.TRANSIT]: 'TRANSIT',
  [ChartType.DIRECTION]: 'DIRECTION',
  [ChartType.PROGRESSION]: 'PROGRESSION',
};

export const PLANET_NAMES_TRANSLATION = {
  [Planet.SUN]: 'SUN',
  [Planet.MOON]: 'MOON',
  [Planet.MERCURY]: 'MERCURY',
  [Planet.VENUS]: 'VENUS',
  [Planet.MARS]: 'MARS',
  [Planet.JUPITER]: 'JUPITER',
  [Planet.SATURN]: 'SATURN',
  [Planet.URANUS]: 'URANUS',
  [Planet.NEPTUNE]: 'NEPTUNE',
  [Planet.PLUTO]: 'PLUTO',
  [Planet.CHIRON]: 'CHIRON',
  [Planet.LILITH]: 'LILITH',
  [Planet.NNODE]: 'NNODE',
  [Planet.SNODE]: 'SNODE',
};

export const ZODIAC_NAMES_TRANSLATION = {
  [Zodiac.ARIES]: 'ARIES',
  [Zodiac.TAURUS]: 'TAURUS',
  [Zodiac.GEMINI]: 'GEMINI',
  [Zodiac.CANCER]: 'CANCER',
  [Zodiac.LEO]: 'LEO',
  [Zodiac.VIRGO]: 'VIRGO',
  [Zodiac.LIBRA]: 'LIBRA',
  [Zodiac.SCORPIO]: 'SCORPIO',
  [Zodiac.SAGITTARIUS]: 'SAGITTARIUS',
  [Zodiac.CAPRICORN]: 'CAPRICORN',
  [Zodiac.AQUARIUS]: 'AQUARIUS',
  [Zodiac.PISCES]: 'PISCES',
};

export const ZODIAC_ELEMENT_TRANSLATION = {
  [ZodiacElement.AIR]: 'spirits.air',
  [ZodiacElement.FIRE]: 'spirits.fire',
  [ZodiacElement.WATER]: 'spirits.water',
  [ZodiacElement.EARTH]: 'spirits.earth',
};

export const ASPECT_NAME_TRANSLATION = {
  [Aspect.CONJUNCTION]: 'CONJUNCTION',
  [Aspect.SEXTIL]: 'SEXTIL',
  [Aspect.SQUARE]: 'SQUARE',
  [Aspect.TRINE]: 'TRINE',
  [Aspect.OPPOSITION]: 'OPPOSITION',
  [Aspect.QUICONS]: 'QUICONS',
  [Aspect.SEMI_SEXTIL]: 'SEMI_SEXTIL',
};

const commonAspectList = [Aspect.CONJUNCTION, Aspect.SEXTIL, Aspect.SQUARE, Aspect.TRINE, Aspect.OPPOSITION];

export const DEGREE_ASPECT_MAP = {
  0: Aspect.CONJUNCTION,
  30: Aspect.SEMI_SEXTIL,
  60: Aspect.SEXTIL,
  90: Aspect.SQUARE,
  120: Aspect.TRINE,
  150: Aspect.QUICONS,
  180: Aspect.OPPOSITION,
};

export const CHART_ASPECTS_MAP = {
  [ChartType.NATAL]: commonAspectList,
  [ChartType.SYNASTRY]: commonAspectList,
  [ChartType.SOLAR]: commonAspectList,
  [ChartType.TRANSIT]: commonAspectList,
  [ChartType.DIRECTION]: [
    Aspect.CONJUNCTION,
    Aspect.SEMI_SEXTIL,
    Aspect.SEXTIL,
    Aspect.SQUARE,
    Aspect.TRINE,
    Aspect.QUICONS,
    Aspect.OPPOSITION,
  ],
  [ChartType.PROGRESSION]: commonAspectList,
};

export const CUSP_NUMERICAL_NAMES = {
  [Cusp.As]: 'I',
  [Cusp.II]: 'II',
  [Cusp.III]: 'III',
  [Cusp.Ic]: 'IV',
  [Cusp.V]: 'V',
  [Cusp.VI]: 'VI',
  [Cusp.Ds]: 'VII',
  [Cusp.VIII]: 'VIII',
  [Cusp.IX]: 'IX',
  [Cusp.Mc]: 'X',
  [Cusp.XI]: 'XI',
  [Cusp.XII]: 'XII',
};

export const NUMBER_CUSP_NAME_MAP = {
  1: CUSP_NUMERICAL_NAMES[Cusp.As],
  2: CUSP_NUMERICAL_NAMES[Cusp.II],
  3: CUSP_NUMERICAL_NAMES[Cusp.III],
  4: CUSP_NUMERICAL_NAMES[Cusp.Ic],
  5: CUSP_NUMERICAL_NAMES[Cusp.V],
  6: CUSP_NUMERICAL_NAMES[Cusp.VI],
  7: CUSP_NUMERICAL_NAMES[Cusp.Ds],
  8: CUSP_NUMERICAL_NAMES[Cusp.VIII],
  9: CUSP_NUMERICAL_NAMES[Cusp.IX],
  10: CUSP_NUMERICAL_NAMES[Cusp.Mc],
  11: CUSP_NUMERICAL_NAMES[Cusp.XI],
  12: CUSP_NUMERICAL_NAMES[Cusp.XII],
};

export const NAME_CUSP_NUMBER_MAP = {
  [CUSP_NUMERICAL_NAMES[Cusp.As]]: 1,
  [CUSP_NUMERICAL_NAMES[Cusp.II]]: 2,
  [CUSP_NUMERICAL_NAMES[Cusp.III]]: 3,
  [CUSP_NUMERICAL_NAMES[Cusp.Ic]]: 4,
  [CUSP_NUMERICAL_NAMES[Cusp.V]]: 5,
  [CUSP_NUMERICAL_NAMES[Cusp.VI]]: 6,
  [CUSP_NUMERICAL_NAMES[Cusp.Ds]]: 7,
  [CUSP_NUMERICAL_NAMES[Cusp.VIII]]: 8,
  [CUSP_NUMERICAL_NAMES[Cusp.IX]]: 9,
  [CUSP_NUMERICAL_NAMES[Cusp.Mc]]: 10,
  [CUSP_NUMERICAL_NAMES[Cusp.XI]]: 11,
  [CUSP_NUMERICAL_NAMES[Cusp.XII]]: 12,
};

export const ASPECTS_CONFIGURATION_NAMES = {
  'Tau-square': 'Aspects.Tau-square',
  Carriage: 'Aspects.Carriage',
  Bisectile: 'Aspects.Bisectile',
  'Big trigon': 'Aspects.BigTrigon',
  'Big square': 'Aspects.BigSquare',
  'Oblique sail': 'Aspects.ObliqueSail',
  Sail: 'Aspects.Sail',
  Trapeze: 'Aspects.Trapeze',
};

export const INTERPRETATION_TYPE = {
  ASPECT: 'aspect',
  ZODIAC: 'zodiac',
  CUSP: 'cusp',
  PLANET: 'planet',
};

export const TARIFF_PLAN = {
  FREE: 'USER',
  PREMIUM: 'USER_PREMIUM',
  ADMIN: 'ADMIN',
  TRIAL: 'TRIAL',
  USER_TRIAL: 'USER_TRIAL',
};
/*export const TARIFF_PLAN = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
  ADMIN: 'ADMIN',
  TRIAL: 'TRIAL',
};*/

export const TARIFF_PLAN_TRANSLATION = {
  [TARIFF_PLAN.FREE]: 'Стандарт',
  [TARIFF_PLAN.PREMIUM]: 'Премиум',
  [TARIFF_PLAN.ADMIN]: 'Администратор',
  [TARIFF_PLAN.TRIAL]: 'Премиум',
};

export const STRIPE_PAYMENT_PLAN = {
  STANDART: 'SUBSCRIPTION_STANDART',
  PREMIUM: 'SUBSCRIPTION_PREMIUM',
};

/*
  TODO: Я думаю, нам в будущем надо инкапсулировать логику вокруг обращения к
  firstPersonData и secondPersonData, иначе везде эти if'ы, везде нужны проверки
  на то, что они не null и так далее. Будет видимо большой рефакторинг.
 */
export const PERSON = {
  FIRST: 'firstPersonData',
  SECOND: 'secondPersonData',
};
