import { render, staticRenderFns } from "./ClosableNotificationItem.vue?vue&type=template&id=7f160075&scoped=true&"
import script from "./ClosableNotificationItem.vue?vue&type=script&lang=js&"
export * from "./ClosableNotificationItem.vue?vue&type=script&lang=js&"
import style0 from "./ClosableNotificationItem.vue?vue&type=style&index=0&id=7f160075&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f160075",
  null
  
)

export default component.exports