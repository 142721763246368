import _ from 'lodash';
import { Planet, Aspect, ChartType } from 'astro-chart';

import { CHART_ASPECTS_MAP, CUSP_NUMERICAL_NAMES } from '@/types';

const fillTable = (entities, defaultAspects, value) =>
  entities.reduce((entityAcc, entity) => {
    entityAcc[entity] = defaultAspects.reduce((aspectAcc, aspect) => {
      aspectAcc[aspect] = value;
      return aspectAcc;
    }, {});
    return entityAcc;
  }, {});

const fillTableWithPlanets = (defaultAspects, value) => fillTable(Object.values(Planet), defaultAspects, value);

const DEFAULT_NATAL_ORBS = {
  [Planet.CHIRON]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.JUPITER]: {
    [Aspect.CONJUNCTION]: 7,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 7,
    [Aspect.TRINE]: 7,
    [Aspect.OPPOSITION]: 7,
  },
  [Planet.LILITH]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 1,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.MARS]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.MERCURY]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.MOON]: {
    [Aspect.CONJUNCTION]: 8,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 8,
    [Aspect.TRINE]: 8,
    [Aspect.OPPOSITION]: 10,
  },
  [Planet.NNODE]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.NEPTUNE]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.PLUTO]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.SNODE]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.SATURN]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.SUN]: {
    [Aspect.CONJUNCTION]: 9,
    [Aspect.SEXTIL]: 6.5,
    [Aspect.SQUARE]: 9,
    [Aspect.TRINE]: 9,
    [Aspect.OPPOSITION]: 9,
  },
  [Planet.URANUS]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
  [Planet.VENUS]: {
    [Aspect.CONJUNCTION]: 5,
    [Aspect.SEXTIL]: 5,
    [Aspect.SQUARE]: 5,
    [Aspect.TRINE]: 5,
    [Aspect.OPPOSITION]: 5,
  },
};

const DEFAULT_TRANSIT_ORBS = fillTable(
  [...Object.values(Planet), ...Object.values(CUSP_NUMERICAL_NAMES)],
  CHART_ASPECTS_MAP[ChartType.TRANSIT],
  1,
);

const DEFAULT_PROGRESSION_ORBS = fillTableWithPlanets(CHART_ASPECTS_MAP[ChartType.PROGRESSION], 1);

const DEFAULT_SOLAR_ORBS = fillTableWithPlanets(CHART_ASPECTS_MAP[ChartType.SOLAR], 5);

const DEFAULT_DIRECTION_ORBS = fillTableWithPlanets(CHART_ASPECTS_MAP[ChartType.DIRECTION], 1);

export const initOrbsConfig = () => {
  return {
    [ChartType.NATAL]: _.cloneDeep(DEFAULT_NATAL_ORBS),
    [ChartType.TRANSIT]: _.cloneDeep(DEFAULT_TRANSIT_ORBS),
    [ChartType.SYNASTRY]: _.cloneDeep(DEFAULT_NATAL_ORBS),
    [ChartType.SOLAR]: _.cloneDeep(DEFAULT_SOLAR_ORBS),
    [ChartType.DIRECTION]: _.cloneDeep(DEFAULT_DIRECTION_ORBS),
    [ChartType.PROGRESSION]: _.cloneDeep(DEFAULT_PROGRESSION_ORBS),
  };
};
