import moment from 'moment';
import Vue from 'vue';

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  setLoggedIn(state, value) {
    state.loggedIn = value;
  },
  setUser(state, user) {
    state.user = user;
  },
  setSidebarExpansion(state, value) {
    state.sidebarExpanded = value;
  },
  setBanners(state, data) {
    state.banners = [...data];
  },
  addDisabledBanner(state, bannerId) {
    state.disabledBanners.push({
      id: bannerId,
      expiredAt: moment().add(1, 'w').format(),
    });
  },
  removeDisabledBanner(store, bannerId) {
    store.disabledBanners = store.disabledBanners.filter(({ id }) => id !== bannerId);
  },
  setZoomSettings(state, data) {
    state.settings.zoomEnabled = data;
  },
  setZoomEnabled(state, { type, value } = {}) {
    state.settings.zoomEnabled[type] = value;
  },
  setCalculatedAspectType(state, type) {
    state.settings.calculatedAspectType = type;
  },
  setCalculatedOrbType(state, type) {
    state.settings.calculatedOrbType = type;
  },
  setHouseSystem(state, type) {
    state.settings.houseSystem = type;
  },
  setOrbsConfig(state, config) {
    state.settings.orbsConfig = config;
  },
  setOrbValue(state, { chartType, planetName, aspectName, value } = {}) {
    state.settings.orbsConfig[chartType][planetName][aspectName] = value;
  },
  setAspectActiveState(state, { chartType, aspect, value }) {
    state.settings.aspectsConfig[chartType][aspect].active = value;
  },
  updateChartSettings(state, updatedSettings) {
    Vue.set(state, 'settings', updatedSettings);
  },
  addNotification(state, { text, action, interval, img, remember }) {
    state.notifications.push({
      id: state.lastNotificationId,
      text,
      action,
      interval,
      img,
      remember,
    });

    state.lastNotificationId++;
  },
  removeNotification(state, notification) {
    const index = state.notifications.indexOf(notification);
    state.notifications.splice(index, 1);
  },
  addPaymentHistory(state, history) {
    state.paymentHistory.push(...history);
  },
  setPaymentHistory(state, history) {
    state.paymentHistory = history;
  },

  setUpdateMsg(state, text) {
    state.lastPassUpdate = text;
  },
  closeTrialBanner(state) {
    state.trialBannerOpened = false;
  },
  setOrbPresets(state, data) {
    state.orbPresets = data;
  },
  setCarrotHash(state, hash) {
    state.carrotHash = hash;
  },

  setLocale(state, locale) {
    state.locale = locale;
  },
  setTariffsInfo(state, tariffs) {
    state.tariffs = tariffs;
  },
  clickModal(state, clickedModal) {
    state.clickedModal = clickedModal;
  },
};

export default mutations;
