<template>
  <action-view v-on="$listeners" :class="`button button_color_${color}`" :disabled="loading">
    <div v-if="title" class="button__title">
      <v-progress-circular v-if="loading" indeterminate class="loading" width="1.5" size="18"></v-progress-circular>
      {{ title }}
    </div>
    <slot />
  </action-view>
</template>

<script>
import ActionView from '../atoms/ActionView';

export default {
  name: 'AstroButton',
  components: { ActionView },
  props: {
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      validator: function (value) {
        return ['primary', 'secondary', 'light'].indexOf(value) !== -1;
      },
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="sass">
.button
  display: flex
  align-items: center
  justify-content: center

  height: base-unit(36)
  padding: 0 base-unit(20)

  border-radius: base-unit(4)

  font-weight: 400
  font-size: base-unit(16)
  line-height: base-unit(19)
  border: base-unit(1) solid blue-color('primary')

  &__title
    display: flex
    align-items: center
    position: relative

    white-space: nowrap
    text-overflow: ellipsis

  &_color_primary
    color: #fff
    background-color: blue-color('primary')

    &:hover
      background-color: blue-color('light')

    &:active
      background-color: blue-color('secondary')


  &_color_secondary
    color: blue-color('primary')
    background-color: #FFF

    &:hover
      color: blue-color('light')
      border-color: blue-color('light')

    &:active
      color: blue-color('secondary')
      border-color: blue-color('secondary')

    .loading
      color: grey-color('400')
      border-color: grey-color('400')

  &_color_light
    color: blue-color('primary')
    border-color: transparent
    background: transparent
    padding: 0
    height: auto

    &:hover
      color: blue-color('light')

    &:active
      color: blue-color('secondary')

    .loading
      color: grey-color('400')

.loading
  position: absolute
  left: -1 * base-unit(32)
</style>
