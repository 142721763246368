import axios from 'axios';
import router from './router';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept-Language'] = 'en-US';

axios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if ((error.response && error.response.status >= 500) || !error.response) {
      if (router.currentRoute.name !== 'connectionRefused') {
        router.replace({ name: 'connectionRefused' });
      }
    }

    return Promise.reject(error.response || error);
  },
);
