<template>
  <notification-item :img="img" :interval="interval" @timeout="onClose">
    <template class="notification-content" v-slot:text>
      <slot></slot>
    </template>
    <template v-slot:button>
      <astro-button v-if="action" class="cancel-btn" :title="$t('login.cancel')" color="light" @click="action" />
      <cross-button class="cross-btn" color="grey" @click="onClose" />
    </template>
  </notification-item>
</template>

<script>
import NotificationItem from './NotificationItem';
import CrossButton from '@/elements/components/CrossButton';
import AstroButton from '@/elements/components/AstroButton';

export default {
  name: 'ClosableNotificationItem',
  components: { CrossButton, NotificationItem, AstroButton },
  props: {
    action: {
      type: Function,
      default: null,
    },
    interval: {
      type: Number,
      default: 3000,
    },
    img: {
      type: String,
    },
    remember: {
      type: String,
    },
  },
  methods: {
    onClose() {
      if (this.remember) {
        //sessionStorage.setItem(this.remember, 'checked');
        this.$cookies.set(this.remember, 'checked', '7d');
      }
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="sass">
.cross-btn
  width: auto
  height: auto
  padding: base-unit(10) base-unit(10)
  +medium-devices
    padding: base-unit(15) base-unit(20)

.cancel-btn
  grid-row: 2/3
  grid-column: 1/3

  width: 100%
  padding: 0 base-unit(10) base-unit(10) base-unit(10)
  font-weight: 400
  +medium-devices
    width: auto
    padding: 0 base-unit(10)
</style>
